import axios from 'axios';

// constants
import { API_URL_NEW } from '../lib/endpoints'

// helpers
import { prepareFilterParam, prepareSelectParam } from '../lib/queryParams';

// common functions for all entities

const entityApi = {

    getUserTeamStatus: (entityId, entityType) => {
        const url = `${API_URL_NEW}/${entityType}/${entityId}/team-membership-status`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data.data)
            }).catch(err => {
                reject(err)
            })
        });
    },

    getRelatedNews: (entityId, entityType) => {
        const url = `${API_URL_NEW}/${entityType}/${entityId}/news`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data.data)
            }).catch(err => {
                reject(err)
            })
        });
    },

    getCompanyEmail: (entityId, entityType) => {
        const fields = prepareSelectParam(["email_relations"]);

        const url = `${API_URL_NEW}/${entityType}/${entityId}?fields=${fields}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data.data)
            }).catch(err => {
                reject(err)
            })
        });
    },

    getEntityName: (entityId = null, entityType = null) => {
        const fields = prepareSelectParam(["name"]);

        const url = `${API_URL_NEW}/${entityType}/${entityId}?fields=${fields}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                resolve(response.data.data)
            }).catch(err => {
                reject(err)
            })
        });
    },

    getEntityDetails: (entityId, entityType,fieldsArr) => {
        const fields = prepareSelectParam(fieldsArr);
        const url = `${API_URL_NEW}/${entityType}/${entityId}?fields=${fields}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                const resp = response.data.data;
                if (response.data.status == 200 && resp.id) {
                    resolve(resp);
                }
            }).catch(error => {
                reject(error);
            })
        });
    },

    getInvestors: (entityId, entityType, limit = 6, page = 1, getFilters = []) => {
        let filterQuery = '';
        if (getFilters && getFilters.length > 0) {
            filterQuery = `filters=${prepareFilterParam(getFilters)}&`;
        }
        const url = `${API_URL_NEW}/${entityType}/${entityId}/investor?${filterQuery}limit=${limit}&page=${page}`;

        return new Promise((resolve, reject) => {
            if (entityType && entityId) {
                axios.get(url).then((response) => {
                    const list = response.data.data;
                    resolve({
                        records: list,
                        totalRecords: response.data.navigation.total
                    });
                }).catch(err => {
                    reject(err)
                })
            } else {
                resolve({ records: [], totalRecords: 0 })
            }
        });
    },
}

export default entityApi;