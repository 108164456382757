import { sharedQueryParams } from 'shared-helpers';

export const prepareFilterParam = sharedQueryParams.prepareFilterParam;
export const prepareSelectParam = sharedQueryParams.prepareSelectParam;
export const prepareSortParam = sharedQueryParams.prepareSortParam;



/**
 * Method to prepare pagination query param syntax
 * 
 * @param {Array}   params   Array of objects to perform pagination
 * @example[{
     key: 'limit',
     value: '10'
 }, {
     key: 'page',
     value: '1'
 }]
 * @returns {String}            Returns a prepared query param string
 */
export const preparePaginationParams = (params = []) => {
    // Default pagination param
    if (params.length < 1) {
        return 'limit=20&page=1'
    }
    const parsedParamsArray =  params.map(param => `${param.key}=${param.value}`)
    return parsedParamsArray.join('&')
}