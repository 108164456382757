import React, { useEffect, useState } from 'react';
//api
import taxonomyApi from '../../../apis/taxonomyApi';


export default function SelectedMapOptions(props) {

    const [regions, setRegions] = useState([]);
    const [locations, setLocations] = useState([]);
    const [industries, setIndustries] = useState([]);

    useEffect(() => {
        const apiCalls = [
            [taxonomyApi.getRegions, setRegions],
            [taxonomyApi.getLocations, setLocations],
            [taxonomyApi.getIndustries, setIndustries]
        ]

        apiCalls.forEach(([api, setter]) => {
            api().then(res => {
                if (res.length > 0) setter(res);
            })
        })

    }, []);

    const prepareSelectedOptionsList = () => {
        const { mapFilters } = props;

        const tempObj = {}
        const selectedOptionsObj = {}
        const selectedOptionsArrKey = []

        // add map type
        if (props.mapType) {
            let type = props.mapType.replace(/_/g, ' ');
            selectedOptionsObj["map type"] = [type.charAt(0).toUpperCase() + type.slice(1)];
        }

        const filterMappings = {
            'region': { list: regions, displayName: 'region' },
            'hq_id': { list: locations, displayName: 'headquartered' },
            'primary_industry_filter': { list: industries, displayName: 'industry focus' }, // for startup mm
            'industry': { list: industries, displayName: 'industry focus' }, // for inv imm
        };

        if (mapFilters && mapFilters.length > 0) {

            mapFilters.forEach(item => {
                tempObj[item.key] = item.value;
                selectedOptionsArrKey.push(item.key);

                if (item.key in filterMappings) {
                    filterSelectedValues(filterMappings[item.key].list, item.key);
                }
            });
        }

        function filterSelectedValues(fetchList, fieldKey) {

            if (fetchList.length === 0 || tempObj[fieldKey].length === 0) {
                return;
            }
            const list = fetchList
                .filter(item => tempObj[fieldKey].includes(`${item.id}`) || tempObj[fieldKey].includes(Number(item.id)))
                .map(item => item.name);

            if (filterMappings[fieldKey]) {
                selectedOptionsObj[filterMappings[fieldKey].displayName] = list
            } else {
                selectedOptionsObj[fieldKey] = list
            }
        }

        // add sorting option
        if (props.mapSorting && props.mapSorting.label) {
            selectedOptionsObj["sorted by"] = [props.mapSorting.label]
        }
        // add grouping option
        if (props.mapGrouping && props.mapGrouping.length > 0) {
            selectedOptionsObj["grouped by"] = props.mapGrouping.map(item => item.name)
        }

        return { selectedOptionsObj, selectedOptionsArrKey }
    }

    const makeListTooltip = (data) => {

        // check if array of strings or array of objects
        let selected = [];
        if (String(data[0]) === data[0]) {
            selected = data.map(e => String(e));
        } else {
            selected = data;
        }

        // display 1st val or show tooltip if 1+
        if (selected.length === 1) {
            return (selected[0]);
        } else {
            const text = selected.map(e => String(e)).join(", ");
            return (
                <span>
                    {selected.length} selected
                    <span data-toggle="tooltip" data-original-title={text} className="selected-filter-tooltip" data-placement="top">
                        <i className="fas fa-info-circle mr-0"></i>
                    </span>
                </span>
            )
        }
    }

    const renderSelectedOptions = () => {

        const { selectedOptionsObj, selectedOptionsArrKey } = prepareSelectedOptionsList();
        const filterKeys = Object.keys(selectedOptionsObj);
        let arr = [];

        if (filterKeys.length > 0) {
            arr = filterKeys.map((key, index) => {
                if (selectedOptionsObj[key].length > 0) {
                    return (
                        <span key={`${key}_${index}`}>
                            {/* comma separate for the next filter */}
                            {index > 0 && index < filterKeys.length ? ", " : ""}
                            <span className="sub-bold">{`${key.charAt(0).toUpperCase() + key.slice(1)}: `}</span>
                            <span>{makeListTooltip(selectedOptionsObj[key])}</span>
                        </span>
                    )
                }
            })
        }

        return (
            <div className="filters-header mt-0">
                <p className="selected-marketMap-filters">
                    <span className="font-weight-bold">Selected filters - </span>
                    {arr}
                </p>
            </div>
        )
    }

    return (renderSelectedOptions())
}
