import React from 'react';
import { Helmet } from "react-helmet";

// reusable
import OpenPositions from './components/OpenPositions';

// constant
import { WEB_URL, PUBLIC_IMG_URL } from '/src/lib/endpoints';
import { DEF_MAGNITT_DESCRIPTION } from '/src/lib/constants';



export const Internships = () => {
    return (
        <div className="careers-page">

            <Helmet
                title={'Internships | MAGNiTT'}
                link={[
                    {
                        rel: 'canonical',
                        href: `${WEB_URL}/internships`
                    }
                ]}
                meta={[
                    {
                        name: 'description',
                        content: DEF_MAGNITT_DESCRIPTION
                    },
                    {
                        name: 'abstract',
                        content: DEF_MAGNITT_DESCRIPTION
                    },
                    {
                        property: 'og:type',
                        content: 'article'
                    },
                    {
                        property: 'og:url',
                        content: `${WEB_URL}/internships`
                    },
                    {
                        property: 'og:title',
                        content: 'MAGNiTT Internships'
                    },
                    {
                        property: 'og:image',
                        content: `${PUBLIC_IMG_URL}/careers/careers-share-image.jpg`
                    },
                    {
                        property: 'og:image:secure_url',
                        content: `${PUBLIC_IMG_URL}/careers/careers-share-image.jpg`
                    },
                ]}
                script={[
                    {
                        'type': 'application/ld+json',
                        'innerHTML': `{
                        "@context": "http://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [{
                            "@type": "ListItem",
                            "position": 1,
                            "name": "jobs",
                            "item": "${WEB_URL}/internships"
                        }]
                    }`
                    }
                ]}
            />

            <div className="careers-hero-section">
                <div className="video-box">
                    <div className="video-overlay">
                        <div>
                            <h1>We're here to create something epic! Are you up for the challenge?</h1>
                            <a className="btn btn-lg btn-main" href="#open-positions">
                                Explore internships
                                <i className="fas fa-arrow-down"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="lines-graphic lines-graphic-left"></div>
            </div>

            <OpenPositions jobType="internships" />

        </div>
    )
}