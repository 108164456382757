import axios from 'axios';

// util
import { BIGQUERY_URL, API_URL_NEW } from '../lib/endpoints';

const ecosystemMarketMapApi = {
    getEcosystemDirectoryLandscape: (path, fields, filters, limit, page, sort) => {
        const url = `${BIGQUERY_URL}/investors/${path}?fields=${fields}&filters=${filters}&limit=${limit}&page=${page}&sort=${sort}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

    getEcosystemLandscapePagination: (path, id, fields, filters, limit, page, sort) => {
        const url = `${BIGQUERY_URL}/investors/${path}/pagination/${id}?fields=${fields}&filters=${filters}&limit=${limit}&page=${page}&sort=${sort}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve(response.data.data[0].investor_detail_data);
                } else {
                    resolve([]);
                }
            }).catch((err) => reject(err));
        });
    },

    /*
     * get investor list
     * use case: table view & show more in landscape view
     */
    getInvestors: (fields, filters, page, sort, limit) => {
        let url = `${API_URL_NEW}/ecosystem/?fields=${fields}&filters=${filters}&limit=${limit}&page=${page}&sort=${sort}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then(response => {
                resolve(response)
            }).catch(err => {
                if (!axios.isCancel(err)) {
                    reject(err)
                }
            })
        })
    },
}

export default ecosystemMarketMapApi;