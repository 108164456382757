// parameters expected by back end

export const fields = {
    round: [
        "funding_stage_id", 
        "post_money_valuation",
        "amount_raised",
        // for investors adding rounds, the business they invested in
        // not req for startups adding rounds
        'investedin_id',
        'investedin_name'
    ],
    breakdown: [
        "investment_vehicle_id", 
        "date_raised", 
        "amount"
    ],
    investor: [
        "deal_term_id", 
        "entity_id", 
        "entity_type", 
        "invested_amount", 
        "entity_name", 
        "entity_code"
    ]
}