import React from 'react'
import { Helmet } from "react-helmet";

import { WEB_URL, PUBLIC_IMG_URL } from "../../lib/endpoints";

export default function PageHelmet({pageURL, pageTitle, pageDescription, previewImageUrl}) {
    return (
        <Helmet
            title={`${pageTitle} | MAGNiTT`}
            link={[
                {
                    rel: "canonical",
                    href: `${WEB_URL}/${pageURL}`
                }
            ]}
            meta={[
                {
                    name: "description",
                    content: pageDescription
                },
                {
                    name: "abstract",
                    content: pageDescription
                },
                {
                    property: "og:type",
                    content: "article"
                },
                {
                    property: "og:url",
                    content: `${WEB_URL}/${pageURL}`
                },
                {
                    property: "og:title",
                    content: `${pageTitle} | MAGNiTT`
                },
                {
                    property: "og:image",
                    content: previewImageUrl ? `${previewImageUrl}` : `${PUBLIC_IMG_URL}/main_logo.png`
                },
            ]}
            script={[
                {
                    "type": "application/ld+json",
                    "innerHTML": `{
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [{
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": ${pageURL}",
                                    "item": "${WEB_URL}/${pageURL}"
                                }]
                            }`
                }
            ]}
        />
    )
}
