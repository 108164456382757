import React from 'react'
import MicroFrontend from "../MicroFrontend"

const MagnittFooter = ({history}) => {
    return <MicroFrontend
        history={history}
        name="MagnittFooter"
    />
}

export default (MagnittFooter);