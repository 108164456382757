import React from 'react';
import { useSelector } from 'react-redux';

// reusable
import PageHelmet from "/src/components/reusable/PageHelmet";

// constant
import { MAGNITT_ACCESS_ROLES } from '/src/lib/constants/userRoles';

// page content
import { CustomMarketMapsLandingPage } from './CustomMarketMapsLanding';
import { ManageCustomMarketMaps } from './ManageCustomMarketMaps';



const CustomMarketMaps = () => {

    // check if user's logged in + has access to custom market maps
    const user = useSelector((state) => state.userReducer);
    const hasAccess = user.is_loggedin && user.roles.includes(MAGNITT_ACCESS_ROLES.directory_access);

    let PageContent = CustomMarketMapsLandingPage;
    if (hasAccess) {
        PageContent = ManageCustomMarketMaps;
    }

    return (
        <div className="footer-margin-offset">
            <PageHelmet
                pageURL="market-maps"
                pageTitle="Custom Market Maps"
                pageDescription="MAGNiTT Custom Market Maps"
            />
            <PageContent />
        </div>
    )

}

export default CustomMarketMaps;