const initialState = {
    is_logintab: false,
    is_loggedin: false,
    refresh_token: '',
    token: '',
    user_id: '',
    user_name: '',
    full_name: '',
    profile_pic: '',
    email: '',
    roles: '',
    email_status: '',
    selected_report_id: null
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case 'HIDE_LOGIN_TAB':
        return {
            ...state,
            is_logintab: false,
        }
    case 'SHOW_LOGIN_TAB':
        return {
            ...state,
            is_logintab: true,
        }
    case 'SET_USER_INFO':
        return {
            ...state,
            refresh_token: action.refresh_token,
            is_loggedin: action.isloggedin,
            user_id: action.id,
            full_name: action.fullname,
            user_name: action.username,
            profile_pic: action.pic,
            email: action.email,
            roles: action.roles,
            email_status: action.email_status
        }
    case 'SET_USER_ROLES':
        return {
            ...state,
            roles: action.roles
        }
    case 'SET_USER_PROFILE_PIC':
        return {
            ...state,
            profile_pic: action.pic
        }
    case 'SET_NEW_TOKEN':
        return {
            ...state,
            token: action.token
        }
    case 'SET_USER_STATUS':
        return {
            ...state,
            email_status: action.status
        }
    case 'SET_SELECTED_REPORT':
        return {
            ...state,
            selected_report_id: action.selectedReportId
        }
    case 'LOGOUT':
        //store.remove('token')
        return initialState;
    default:
        return {
            ...state
        }
    }
}