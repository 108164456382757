import React from 'react';



export const OurMemories = () => {

    /* 
     * note:
     * this was the best method I could find, as Instagram's API sometimes requires users to log in
     * it also returns CORS errors when you try getting the post image
     * 
     * widget documentation: https://woxo.tech/instagram-widget
     */
    const instagramWidget = <div loading="lazy" data-mc-src="01602b11-e0b0-4b5a-ba97-a900d9db1cbb#null"></div>

    return (
        <div className="careers-memories-section">
            <div className="careers-heading text-center">
                Our Memories
            </div>
            <div className="instagram-widget">
                {instagramWidget}
            </div>
        </div>
    )

}