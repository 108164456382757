import React from 'react'
import { Helmet } from 'react-helmet';
import { sharedEndpoints } from 'shared-helpers';
import MicroFrontend from "../MicroFrontend"

const WEB_URL = sharedEndpoints.WEB_URL;

const NoMatch = ({ history }) => {
    return <>
        <Helmet
            title="404 page not found"
            link={[
                {
                    rel: 'canonical',
                    href: `${WEB_URL}/not-found`,
                },
            ]}
            meta={[
                {
                    name: 'description',
                    content: 'Sorry, page not found',
                },
                {
                    name: 'abstract',
                    content: 'Sorry, page not found',
                },
                {
                    property: 'og:url',
                    content: `${WEB_URL}/not-found`,
                },
                {
                    property: 'og:title',
                    content: '404 page not found',
                },
            ]}
            script={[
                {
                    type: 'application/ld+json',
                    innerHTML: `{
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [{
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "404 page",
                                    "item": "${WEB_URL}/not-found"
                                  }]
                            }`,
                },
            ]}
        />
        <MicroFrontend
            history={history}
            name="NoMatch"
        />
    </>
}

export default (NoMatch);