import React from 'react';
import { PUBLIC_IMG_URL } from '/src/lib/endpoints';



export const EmployeeReviews = () => {

    /* note:
       check mobile layout when updating
       if the review is too long, it extends the height of the screen and you need to scroll to use the next/prev btns
     */
    const reviews = [
        {
            reviewText: (
                <>
                    <p>People and the culture are the best part of working here. Days go by super quickly because we work on multiple high-impact projects simultaneously.</p>
                    <p>Our every contribution here is seen, acknowledged, and valued by everyone in the company and by industry leaders in the ecosystem.</p>
                    <p>If working in an ambitious, diverse, and supportive environment is what you're looking for, then MAGNiTT is the right place!<span className="quote-mark">”</span></p>
                </>
            ),
            name: "Swati Trivedi",
            jobTitle: "Senior Marketing Executive",
            joinYear: "2021",
            img: "swati.jpg"
        },
        {
            reviewText: (
                <>
                    <p>MAGNiTT is a fast-paced, fun and multicultural environment. With 14+ nationalities working together, there is definitely no day that is the same as the other.</p>
                    <p>I believe what makes us stronger is the common denominator, we are one team and have one goal.</p>
                    <p>It’s refreshing to be part of such a multidisciplinary team and have an actual blast at work!<span className="quote-mark">”</span></p>
                </>
            ),
            name: "Ximena Ortega",
            jobTitle: "Senior Procurement Officer",
            joinYear: "2021",
            img: "ximena.jpg"
        },
        {
            reviewText: (
                <>
                    <p>The workplace and culture of MAGNiTT are something we all take pride in. The culture we have is what differentiates us from other organizations.</p>
                    <p>It is a culture that celebrates diversity, values flexibility, encourages cross-functional collaboration, and appreciates what every person contributes.<span className="quote-mark">”</span></p>
                </>
            ),
            name: "Kavya Ajith ",
            jobTitle: "Full-Stack Developer",
            joinYear: "2021",
            img: "kavya.jpg"
        },
        {
            reviewText: (
                <>
                    <p>Working at MAGNiTT is an amazing experience that goes beyond just a regular job. The company’s culture and values are what make it truly special, with a team that is diverse and unique, like a little world of its own.</p>
                    <p>Not only do you excel in your field, but you also get to learn about every aspect of the business in a transparent and supportive environment.<span className="quote-mark">”</span></p>
                </>
            ),
            name: "Deepak Lodha",
            jobTitle: "Data Scientist",
            joinYear: "2021",
            img: "deepak.jpg"
        },
        {
            reviewText: (
                <>
                    <p>My journey at MAGNiTT gave me the opportunity to take on new challenges, strengthen my skills and unlock my potential as a designer.</p>
                    <p>Being part of a supportive and motivating work culture has been a great experience for me.<span className="quote-mark">”</span></p>
                </>
            ),
            name: "Amira Sharaf",
            jobTitle: "Graphic Designer",
            joinYear: "2022",
            img: "amira.JPG"
        },
    ];

    return (

        <div className="careers-employee-section">
            <div id="emp-carousel-btn" className="carousel slide" data-interval="false">

                {/* slide */}
                <div className="carousel-inner">
                    {reviews.map((rev, i) => {
                        const imgStyle = { backgroundImage: `url(${PUBLIC_IMG_URL}/careers/employee-reviews/${rev.img})` };
                        return (
                            <div className={`carousel-item ${i === 0 ? "active" : ""}`} key={`rev-${i}`}>
                                <div className="card">
                                    <div className="card-img" style={imgStyle}></div>
                                    <div className="card-body">
                                        <h5 className="review-title">
                                            What’s the best part about working at MAGNiTT?
                                        </h5>
                                        <div className="review-text">
                                            <span className="quote-mark">“</span>
                                            {rev.reviewText}
                                        </div>
                                        <div className="reviewer-info">
                                            <div className="card-mobile-img" style={imgStyle}></div>
                                            <div>
                                                <p>{rev.name}</p>
                                                <p>{rev.jobTitle}</p>
                                                <p>Joined MAGNiTT in {rev.joinYear}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

                {/* next/prev btns */}
                <a className="carousel-control-prev" href="#emp-carousel-btn" role="button" data-slide="prev">
                    <img src={`${PUBLIC_IMG_URL}/icons/arrow_left_circle.svg`} className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#emp-carousel-btn" role="button" data-slide="next">
                    <img src={`${PUBLIC_IMG_URL}/icons/arrow_right_circle.svg`} className="carousel-control-prev-icon" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                </a>

                {/* indicator - active slide */}
                <ol className="carousel-indicators">
                    {reviews.map((rev, i) =>
                        <li data-target="#emp-carousel-btn" data-slide-to={i} key={`slide-btn-${i}`} className={`${i === 0 ? "active" : ""}`}></li>
                    )}
                </ol>

            </div>

            <div className="lines-graphic lines-graphic-left"></div>

        </div>
    )
}