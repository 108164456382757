import React, { Component } from 'react'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import loadable from '@loadable/component';
import { cookieHelpers } from 'shared-helpers';

import * as userActions from '../redux/actions/userActions';
import routes from '../routes';
import SegmentHelperClass from '../lib/segmentHelper';

import MsgNotAudio from '/src/audio/msg-notification.mp3';

import MagnittHeader from '../micro-frontends/MagnittHeader';
import MagnittFooter from '../micro-frontends/MagnittFooter';
import SideNav from '../micro-frontends/SideNav';
import NoMatch from '../micro-frontends/NoMatch';

const ErrorBoundary = loadable(() => import(/* webpackChunkName: "ErrorBoundary" */ './ErrorBoundary'));



const SegmentHelper = new SegmentHelperClass();

class App extends Component {

    constructor(props) {
        super(props);
        const { userReducer } = this.props.reduxStore;

        this.state = {
            id: '',
            //showRequestDemo: false,
            email: '',
            emailPlaceholder: '',
            emailError: false,
            shrink: false,
            showSubscribe: false,
            height: '',
            isPlayNotSound: false,
            roles: userReducer.roles,
            userState: userReducer
        }
    }

    componentDidMount() {

        const propsPendingFundingCount = this.props.reduxStore.profileCountReducer.pendingJoinFundingCount;

        window.addEventListener("load", this.handleOnLoad)
        !this.props.reduxStore.userReducer.is_loggedin ? this.setState({ showSubscribe: true }) : this.setState({ showSubscribe: false })

        // If there are unread msgs or pending connections, call the @appendMsgCountToTitle() method
        if (propsPendingFundingCount > 0) {
            setTimeout(() => {
                this.appendMsgCountToTitle()
            }, 3000);
        }

        setTimeout(function () { //Start the timer
            const user = this.props.reduxStore.userReducer;

            SegmentHelper.identifyUser(user);

        }.bind(this), 3000);

        this.watchUserCookieChanges();

    }

    /**
     * watch for user cookie changes from shared
     * then compare to react/content cookie & if there's a mismatch, update view
     */
    watchUserCookieChanges = () => {
        const { userState } = this.state;
        cookieHelpers.watchCookieChanges(userState, this);
    }

    componentWillUnmount() {
        let eventName = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i) ? 'pagehide' : 'beforeunload'
        // window.addEventListener(eventName, this.handleBeforeUnload)
        window.removeEventListener("load", this.handleOnLoad)
        //window.addEventListener("scroll", this.handleScroll)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        // If snapshot returns true
        // Which means unread msg count has been changed
        // Then modify the title and add unread msgs count to it
        if (snapshot) {
            this.appendMsgCountToTitle();
        }
    }

    /**
     * This method will modify meta title
     * Whenever, there is a new pending funding
     */
    appendMsgCountToTitle() {
        const pendingJoinFundingCount = this.props.reduxStore.profileCountReducer.pendingJoinFundingCount;
        let totalCount = 0;

        if (pendingJoinFundingCount > 0) {
            totalCount += pendingJoinFundingCount;
        }

        if (document && document.title) {

            let existingTitle = document.title;

            if (existingTitle.indexOf(')') == 2) {
                existingTitle = existingTitle.substring(4);
            }

            if (totalCount > 0) {
                document.title = `(${totalCount}) ${existingTitle}`;
            } else {
                document.title = existingTitle;
            }
        }
    }

    /**
     * This method will play sound whenever there is a new message
     */
    handleNewMsgSound = () => {
        const isNewMsgSound = this.props.isNewMsgSound;
        if (isNewMsgSound) {
            return <audio ref="audioRef" src={MsgNotAudio} controls autoPlay />;
        }

        return null;
    }

    render() {

        const { userState } = this.state;
        const { history } = this.props;

        return (
            <div>

                <ToastContainer newestOnTop={true} />

                <SideNav userState={userState} />

                <div className="main-content" id="panel">

                    <MagnittHeader history={history} userState={userState} />

                    <div id='content' className='content-container'>

                        <ErrorBoundary>

                            <Switch>
                                {routes.map(({ path, exact, component: Component, ...props }) => (
                                    <Route
                                        key={path}
                                        path={path}
                                        exact={exact}
                                        render={routeProps => (
                                            <Component {...routeProps} {...props} userState={userState} />
                                        )}
                                    />
                                ))}

                                {/* 301 redirects */}
                                <Redirect exact from={`/enablers`} to={{ pathname: `/investors`, state: { status: 301 } }} />
                                <Redirect exact from={`/corporates`} to={{ pathname: `/investors`, state: { status: 301 } }} />
                                <Redirect exact from={`/subscribe-magnitt-plus`} to={{ pathname: `/pricing`, state: { status: 301 } }} />
                                <Redirect exact from={`/people/:code`} to={{ pathname: `/people/${this.state.id}/:code`, state: { status: 301 } }} />
                                <Redirect exact from={`/savedsearch`} to={{ pathname: `/dashboards`, state: { status: 301 } }} />
                                <Redirect exact from={`/difc-startups`} to={{ pathname: `/client-directory`, state: { status: 301 } }} />
                                <Redirect exact from={`/exits`} to={{ pathname: `/startups`, state: { status: 301 } }} />
                                <Redirect exact from={`/contact-sales`} to={{ pathname: `/pricing`, state: { status: 301 } }} />
                                <Redirect exact from={`/mysettings/cards`} to={{ pathname: `/mysettings`, state: { status: 301 } }} />
                                <Redirect exact from={`/mysettings/payment-history`} to={{ pathname: `/mysettings`, state: { status: 301 } }} />

                                {/* fix for double slashes user typo */}
                                <Redirect from="*//*" to="*/*" />

                                {/*<Route component={NoMatch} />*/}
                                <Route render={(props) => <NoMatch {...props} />} />

                            </Switch>

                            {this.handleNewMsgSound()}

                        </ErrorBoundary>

                        <MagnittFooter />
                    </div>

                </div>

            </div>
        )
    }

}


const mapStateToProps = (state) => ({
    reduxStore: state
})
const mapDispatchToProps = dispatch => ({
    userActions: bindActionCreators(userActions, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
