import React from 'react';
import isEmpty from 'lodash/isEmpty';
import loadable from '@loadable/component';

// components
import DefaultComponent from '/src_temp/components/DirectoryTable/DefaultComponent';

// api
import ecosystemMarketMapApi from '/src/apis/ecosystemMarketMapApi';

// helpers
import { prepareSelectParam, prepareFilterParam, prepareSortParam } from '/src/lib/queryParams';
import { getItemWebUrl, amountFormatter } from '/src/lib/helpers';

// reusable
import MagnittLoader from '/src/micro-frontends/MagnittLoader';
import DirectoryFooter from '/src/pages/directories/reusable/DirectoryFooter';
const DirTable = loadable(
    () => import(/* webpackChunkName: "DirTable" */ '/src/pages/directories/reusable/DirTable')
);

// constants
import { ALLOWED_QUERY_OPERATOR } from '/src/lib/constants';
import { MAGNITT_ACCESS_ROLES } from '/src/lib/constants/userRoles';
import { NO_DATA } from '/src/lib/messages';



class InvestorMarketMapTable extends DefaultComponent {

    constructor(props) {
        super(props);

        this.entityType = 'INVESTOR';

        this.state = {
            data: [],
            page: 1,
            limit: 10,
            isLoading: true,
            isLoadingNewPage: false,
            sort: [{ key: 'number_of_investments', sortVal: 'desc' }],
            field: ['id', 'name', 'ecosystem_type', 'code', 'logo_data', 'description', 'type_data', 'hq_data', 'number_of_investments', 'estimated_deployed_capital', 'number_of_funds', 'number_of_startup_investments', 'industry_data'],
            filter: [
                { key: 'status', value: [1], op: ALLOWED_QUERY_OPERATOR.equal },
                { key: 'is_deleted', value: [0], op: ALLOWED_QUERY_OPERATOR.equal }
            ],
            selectableFields: [
                {
                    fieldName: "Description",
                    keyName: "description"
                },
                {
                    fieldName: "Type",
                    keyName: "type_data"
                },
                {
                    fieldName: "Country",
                    keyName: "hq_data"
                },
                {
                    fieldName: "Industry",
                    keyName: "industry_data",
                },
                {
                    fieldName: "No. of Investments",
                    keyName: "number_of_investments"
                },
                {
                    fieldName: "Estimated Deployed Capital",
                    keyName: "estimated_deployed_capital"
                },
                {
                    fieldName: "No. of Funds",
                    keyName: "number_of_funds"
                },
                {
                    fieldName: "No. of Startups",
                    keyName: "number_of_startup_investments"
                }
            ],
            tempFilter: []
        }
    }

    componentDidMount() {
        this.getInvestors();
    }

    getInvestors = () => {

        this.setState({ isLoadingNewPage: true });

        let { page, sort } = this.state;
        const { limit, field, filter, tempFilter } = this.state;
        const { mapFilters } = this.props;

        // for pagination
        const { isPageChanged } = tempFilter;
        delete tempFilter.isPageChanged;

        let fields = prepareSelectParam(field);

        let defaultFilters = prepareFilterParam(filter);
        let customFilters = prepareFilterParam(mapFilters);
        let finalFilters = customFilters ? defaultFilters + `^,${customFilters}` : defaultFilters;

        let sortParam = prepareSortParam(sort);
        // if page hasn't changed, set it to 1
        // this will reset to page 1 when page was > 1 and filters/sort/limit was changed
        if (!isPageChanged) {
            page = 1;
        }

        ecosystemMarketMapApi.getInvestors(fields, finalFilters, page, sortParam, limit).then((response) => {
            if (!isEmpty(response)) {
                const resp = response.data;
                this.setState({
                    data: resp.data,
                    isLoading: false,
                    isLoadingNewPage: false,
                    count: resp.navigation.total,
                    page
                });
            }
        });
    }

    render() {

        const { user, view, tabIsVisible } = this.props;
        const { isLoading, isLoadingNewPage, data } = this.state;
        const hasTableData = (data && data.length > 0);

        let content = null;
        let tableContent = {};

        tableContent.rows = [];
        tableContent.pinnedRow = [];

        if (data !== false && data.length) {
            data.map((investor, i) => {

                // prepare fields
                // first column
                // logo, name
                let ecosystemTypePath = null;
                if (investor.ecosystem_type) {
                    ecosystemTypePath = `${investor.ecosystem_type.toLowerCase()}s`;
                }

                const profile_url = "/" + getItemWebUrl(ecosystemTypePath, investor.id, investor.code);

                let image;
                if (!isEmpty(investor.logo_data)) {
                    image = encodeURI(investor.logo_data.path);
                    image = <div className="startupLogo" style={{ backgroundImage: `url('${image}')`, }}></div>
                } else {
                    image = <div className='startupLogo'><h2>{investor.name.substr(0, 1).toUpperCase()}</h2></div>
                }

                let nameHeader = 'Name';
                let nameRow = (
                    <div className="d-flex align-items-center">
                        <a className="d-flex align-items-center">
                            <a href={profile_url}>
                                {image}
                            </a>
                            <div className="flex-column" style={{ marginLeft: 10, marginTop: -4 }} >
                                <a className="startup-name" href={profile_url}>
                                    {investor.name}
                                </a>
                            </div>
                        </a>
                    </div>
                );

                // send to table
                // Note // don't change property names - these are used for css styles
                tableContent.header = [nameHeader];
                let fieldData = [{ name: nameRow }];

                // check selectable fields
                this.state.selectableFields.map((field) => {
                    const itemField = investor[field.keyName];
                    let tempData = "-";
                    tableContent.header.push(field.fieldName);

                    if (itemField) {
                        switch (field.keyName) {
                        case "description":
                            tempData = itemField;
                            break;

                        case "hq_data":
                        case "type_data":
                            tempData = itemField.name;
                            break;

                        case "estimated_deployed_capital":
                            tempData = parseInt(itemField, 10);
                            tempData = amountFormatter(tempData);
                            break;

                        case "number_of_investments":
                        case "number_of_funds":
                        case "number_of_startup_investments":
                            tempData = itemField;
                            break;

                        case "industry_data":
                            let arrTemp = [];
                            itemField.map(item => {
                                if (item && item.name) {
                                    arrTemp.push(item.name);
                                }
                            });

                            tempData = arrTemp.length > 0 ? arrTemp.join(', ') : "-";
                            break;

                        default:
                            tempData = "-";
                        }
                    }

                    fieldData.push({ [field.keyName]: tempData });
                })

                tableContent.rows.push(fieldData);
            })

            content = (
                <DirTable content={tableContent}
                    user={user}
                    entityType={this.entityType}
                    state={this.state}
                    filterValues={this.filterValues}
                    tempFilter={this.state.tempFilter}
                    setState={(s, c) => { this.setState(s, c) }}
                />
            )

        }

        return (
            <div className="network-map-main">

                {(isLoading && tabIsVisible) &&
                    <MagnittLoader data={{ height: "300px", background: "transparent" }} />
                }

                {!isLoading &&
                    <>
                        {content}

                        {/* only display pagination for final view, not preview */}
                        {view === 'finalMap' &&
                            <DirectoryFooter
                                // data
                                count={this.state.count}
                                data={this.state.data}
                                loadingDirPage={isLoadingNewPage}
                                user={this.props.user}
                                page={this.state.page}
                                limit={this.state.limit}
                                tempFilter={this.state.tempFilter}
                                isCustomMarketMap={true}
                                // func
                                clearFilter={() => { }}
                                filterValues={this.getInvestors}
                                setState={(s, c) => { this.setState(s, c) }}
                            />
                        }

                        {!hasTableData &&
                            <div className="no-chart-data">
                                <h6>{NO_DATA}</h6>
                            </div>
                        }

                    </>
                }

            </div>
        )
    }
}

export default (InvestorMarketMapTable);
