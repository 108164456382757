import React, {useEffect} from 'react'
import { hydrate, render } from 'react-dom'
import { BrowserRouter, useHistory } from 'react-router-dom';
import { Provider } from "react-redux"
import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import Cookies from 'universal-cookie'
import { AppContainer } from 'react-hot-loader'
import { loadableReady } from '@loadable/component';
import "core-js/stable"
import "regenerator-runtime/runtime"
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync';

// sentry
import * as Sentry from "@sentry/react";
import { SENTRY_DSN_CSR, SENTRY_ERRORS_TO_IGNORE } from './lib/sentry/sentryConstants';
import { cleanErrors } from './lib/sentry/sentryHelpers';

import App from './components/App'
import './scss/index.scss';
import 'react-phone-input-2/lib/style.css';

import { COOKIE_NAME } from './lib/constants';
import InterceptorClass from './lib/interceptor';
import reducer from './redux/reducers'

const cookies = new Cookies()
const preloadedState = window.__REDUX_DATA__
delete window.__REDUX_DATA__

// this causes the console error: Uncaught TypeError: Cannot read property 'remove' of null
// but checking for the #reduxData element will lead to the data not being read correctly, so leave it like this
if (__isBrowser__) {
    document.getElementById('reduxData').remove()
}

// initializes Sentry with browser history to track page/navigation for error tracking and performance monitoring
function SentryInitializer({ children }) {
    if (IS_SENTRY_TRACKING_ENABLED == 1) {
        const history = useHistory();
        useEffect(() => {
            Sentry.init({
                dsn: SENTRY_DSN_CSR,
                // debug: true, // use to debug sentry actions
                integrations: [
                    new Sentry.BrowserTracing({
                        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
                        tracePropagationTargets: ["localhost", /^https:\/\/magnitt\.com/, /^https:\/\/testapp\.magnitt\.com/],
                    }),
                    new Sentry.Replay({
                        maskAllText: false,
                        blockAllMedia: false,
                    }),
                ],
                environment: process.env.NODE_ENV,
                tracesSampleRate: 1.0,
                replaysSessionSampleRate: 0.1,
                replaysOnErrorSampleRate: 1.0,
                ignoreErrors: SENTRY_ERRORS_TO_IGNORE,
                beforeSend(event, hint) {
                    const error = hint.originalException;
                    return cleanErrors(event, error);
                },
            });
        }, [history]);
    } else {
        console.log('Not sending errors to sentry as it is disabled in .env file');
    }

    return children;
}
 
function setReduxCookies(reduxStore) {
    const hostname = window.location.hostname;
    let cookieOptions = { path: '/', domain: 'magnitt.com' };
    let cookieExpiry  = null;

    if (hostname == 'localhost') {
        cookieOptions = { path: '/' };
    }

    // only use this for cross-device testing with IP (needed for login)
    // if (hostname.startsWith(192)) {
    //     cookieOptions = { path: '/' };
    // }

    // If user is loggedin and expiry is already being set
    // Then just take the existing expiry and put it again
    // Otherwise, if there is no expiry which means the function is running for the very first after the login then create a new expiry of 60 days
    if (reduxStore.getState().userReducer && reduxStore.getState().userReducer.is_loggedin) {

        // added try-catch for edge case issue
        // Uncaught TypeError: Cannot read properties of undefined (reading 'tokenExpiresAt')

        try {
            if (cookies.get(COOKIE_NAME) && cookies.get(COOKIE_NAME).tokenExpiresAt) {
                cookieExpiry          = new Date(cookies.get(COOKIE_NAME).tokenExpiresAt);
                cookieOptions.expires = cookieExpiry;
            } else {
                cookieExpiry          = new Date(new Date().getTime() + 60 * 24 * 60 * 60 * 1000);
                cookieOptions.expires = cookieExpiry;
            }
        } catch (err) {
            cookieExpiry          = new Date(new Date().getTime() + 60 * 24 * 60 * 60 * 1000);
            cookieOptions.expires = cookieExpiry;
        }

    }

    const tempCookie = cookies.get(COOKIE_NAME);

    cookies.remove(COOKIE_NAME);

    cookies.set(COOKIE_NAME, JSON.stringify({
        ...tempCookie,
        userReducer: reduxStore.getState().userReducer,
        profileCountReducer: reduxStore.getState().profileCountReducer,
        tokenExpiresAt: cookieExpiry
    }), cookieOptions)
}

const middlewares = [
    thunk,
    createStateSyncMiddleware({}),
];

const reduxStore = createStore(reducer, preloadedState, applyMiddleware(...middlewares));

reduxStore.subscribe(() => {
    setReduxCookies(reduxStore);
})

initStateWithPrevTab(reduxStore);

const Interceptor = new InterceptorClass(reduxStore);
Interceptor.req();
Interceptor.res();

if (process.env.NODE_ENV == 'development') {
    loadableReady(() => {
        const appElement = document.getElementById('app');
        if (appElement) {
            hydrate(
                <AppContainer>
                    <Provider store={reduxStore} >
                        <BrowserRouter>
                            <SentryInitializer>
                                <App />
                            </SentryInitializer>
                        </BrowserRouter>
                    </Provider>
                </AppContainer>,
                appElement
            )
        }
    })
}
else {
    loadableReady(() => {
        const appElement = document.getElementById('app');
        if (appElement) {
            hydrate(
                <Provider store={reduxStore} >
                    <BrowserRouter>
                        <SentryInitializer>
                            <App />
                        </SentryInitializer>
                    </BrowserRouter>
                </Provider>,
                appElement
            )
        }
    })
}

if (process.env.NODE_ENV == 'development' && module.hot) {
    module.hot.accept('./components/App', () => {
        const NewApp = require('./components/App').default;
        loadableReady(() => {
            const appElement = document.getElementById('app');
            if (appElement) {
                render(<NewApp />, appElement)
            }
        })
    })
}
