import { toast } from 'react-toastify';

export default {
    success: (msg, id) => {
        return toast.success(msg, {
            toastId: id ? `toast-${id}` : 'toast-success',
            className: 'toastSuccessStyle',
            progressClassName: 'toastSuccessProgressBar'
        })
    },


    error: (msg, id) => {
        return toast.error(msg, {
            toastId: id ? `toast-${id}` : 'toast-error',
            className: 'toastErrorStyle',
            progressClassName: 'toastErrorProgressBar'
        })
    }
}