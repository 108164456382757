export const VALUES_DATA = [
    {
        line1: "Communicate",
        line2: "fearlessly to build trust",
        icon: "profile-2user.svg",
        desc: "We work hard to earn trust, and we don’t take it for granted. We are open, honest and integrous in everything we do"
    },
    {
        line1: "Become a leader,",
        line2: "become a reference",
        icon: "cup.svg",
        desc: "We have the courage to lead by example and be the go-to reference for comprehensiveness and relevance in the ecosystem"
    },
    {
        line1: "Do more",
        line2: "with less",
        icon: "chart.svg",
        desc: "We passionately work to achieve the best results, in the smartest way"
    },
    {
        line1: "Treat customers",
        line2: "the way we’d like to be treated",
        icon: "messages-3.svg",
        desc: "We elevate customer experience by nurturing a client-centric culture"
    },
    {
        line1: "Leverage",
        line2: "the collective genius",
        icon: "lamp-charge.svg",
        desc: "We relentlessly collaborate to achieve excellence and growth"
    },
    {
        line1: "We are",
        line2: "one team",
        icon: "people.svg",
        desc: "We seek to build strong and lasting relationships within MAGNiTT, while prioritizing collaboration"
    },
    {
        line1: "Be ambitious,",
        line2: "be disruptive",
        icon: "ranking.svg",
        desc: "We are bold - we are not scared of innovating and being creative"
    },
];