import { SET_PENDING_JOIN_FUNDING_COUNT, LOGOUT } from "../types/profileCountTypes"

const initialState = {
    companyCount: '',
    pendingJoinFundingCount: ''
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case SET_PENDING_JOIN_FUNDING_COUNT:
        return {
            ...state,
            pendingJoinFundingCount: action.data,
        }
    case LOGOUT:
        return initialState;
    default:
        return {
            ...state
        }
    }
}
