const initialState = {
    fundingRounds: [],
    fundingRoundsView: [],
    editFundingRound: [],
    startupInvitations: [],
    investorInvitations: []
}

export default function reducer(state = initialState, action) {
    let tempArr;
    switch (action.type) {
    case 'SET_FUNDING_ROUND':
        return {
            ...state,
            fundingRounds: action.data.fundingRounds,
            fundingRoundsView: action.data.fundingRoundsView,
        }
    case 'SET_EDIT_FUNDING_ROUND':
        return {
            ...state,
            editFundingRound: action.data.editFundingRound,
        }
    case 'SET_STARTUP_INVITATIONS':
        return {
            ...state,
            startupInvitations: [...state.startupInvitations, ...[action.data]]
        }
    case 'SET_INVESTOR_INVITATIONS':
        return {
            ...state,
            investorInvitations: [...state.investorInvitations, ...[action.data]],
        }
    case 'UPDATE_STARTUP_INVITATION':
        tempArr = state.startupInvitations;
        tempArr[action.index] = action.data;

        return {
            ...state,
            startupInvitations: tempArr,
        }
    case 'UPDATE_INVESTOR_INVITATION':
        tempArr = state.investorInvitations;
        tempArr[action.index] = action.data;

        return {
            ...state,
            investorInvitations: tempArr,
        }
    default:
        return {
            ...state
        }
    }
}