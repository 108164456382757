import React from 'react';
import MarketMap from './marketMap/MarketMap';



export const MapPreview = (props) => {
    return (
        <div className={`${props.view === 'preview' ? 'responsive-margin-bottom' : ''}`}>
            <MarketMap {...props} />
        </div>
    )
}