import React from 'react';
import { PUBLIC_IMG_URL } from '/src/lib/endpoints';



export const OurBenefits = () => {

    const benefits_list = [
        {
            name: "Visa & insurance",
            icon: "Passport.svg",
        },
        {
            name: "Hybrid working structure",
            icon: "laptop.svg",
        },
        {
            name: "Remote working policies",
            icon: "Work.svg",
        },
        {
            name: "Leave increment based on tenure",
            icon: "airplane.svg",
        },
        {
            name: "Learning platforms",
            icon: "book.svg",
        },
        {
            name: "Career growth",
            icon: "ranking.svg",
        },
    ]

    return (
        <div className="careers-benefits-section">
            <div className="careers-heading text-center">
                Our Benefits
            </div>
            <div className="row">
                {benefits_list.map((benefit, i) =>
                    <div className="col-6 col-sm-4 col-md-4 col-lg-2" key={`benefit-${i}`}>
                        <div className="benefit-box">
                            <img src={`${PUBLIC_IMG_URL}/icons/${benefit.icon}`} />
                            <p>{benefit.name}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}