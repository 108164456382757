import React, { useRef, useEffect } from 'react';
import { RECORD_COUNT_OPTIONS } from '/src/lib/constants';



const DirectoryPaging = (props) => {

    const { loadingDirPage, setState, page, count, limit } = props;
    const last_page_num = Math.ceil(count / limit);

    // funcs

    const changePageSize = (e) => {
        let { tempFilter } = props;
        setState({ limit: e.target.value }, () => 
            props.filterValues(tempFilter)
        );
    }

    const changePage = (page) => {
        let { tempFilter } = props;
        tempFilter.isPageChanged = true;

        // loadingDirPage will change to false at the end of filterValues function in each directory
        setState({ page, loadingDirPage: true }, () => {
            props.filterValues(tempFilter);
        });

        // remove hover component on slider open
        const popovers = document?.querySelectorAll(".popover");
        if (popovers && popovers.length > 0) {
            popovers.forEach(popover => popover.remove());
        }
    }

    // save the previous value of loadingDirPage
    // to scroll to top when a new page is loaded
    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        }, [value]); 
        return ref.current;
    }

    const prev_loadingDirPage = usePrevious(loadingDirPage);
    useEffect(() => {
        if (!loadingDirPage && prev_loadingDirPage) {
            if (window.innerWidth > 550) {
                const dirTables = document.getElementsByClassName("directory-table");

                [...dirTables].forEach((dirTable) => {
                    if (dirTable) {
                        dirTable.scrollIntoView();
                    }
                });
            } else {
                window.scrollTo(0, 0); // for mobile
            }
        }
    }, [loadingDirPage]);

    return (
        <div className="directory-paging">

            <div className="form-group">
                <label htmlFor="pagination-limit-select">Records per page:</label>
                <select className="form-control form-control-sm" id="pagination-limit-select" onChange={e => changePageSize(e)}>
                    {RECORD_COUNT_OPTIONS.map((op) => 
                        <option key={`paging-${op}`}>{op}</option>
                    )}
                </select>
            </div>

            <nav aria-label="dir-pagination">
                <ul className="pagination pagination-sm">

                    {/* prev */}
                    {(page > 1) &&
                        <li className="page-item" onClick={() => changePage(page - 1)}>
                            <a className="page-link" aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </a>
                        </li>
                    }

                    {/* current page label */}
                    <li className="page-item disabled">
                        <a className="page-link">
                            Page {page} of {last_page_num}
                            {loadingDirPage &&
                                <i className="fas fa-circle-notch fa-spin"></i>
                            }
                        </a>
                    </li>

                    {/* next */}
                    {((page + 1) <= last_page_num) &&
                        <li className="page-item" onClick={() => changePage(page + 1)}>
                            <a className="page-link" aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </a>
                        </li>
                    }

                </ul>
            </nav>

        </div>
    );
}

export default DirectoryPaging;