import React from 'react'

export default function Feedback({message}) {
    return (
        <div className="feedback-page-container col-md-5">
            <div className="feedback-page-icon icon-smaller hide-shadow">
                <i className="fas fa-exclamation-circle"></i>
            </div>
            <h5 className="feedback-page-text text-center">
                {message}
            </h5>
        </div>
    )
}
