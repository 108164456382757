import React from 'react';
import { PUBLIC_IMG_URL } from '/src/lib/endpoints';
import { VALUES_DATA } from "../utils/careersConstants"



export default class ValuesSection extends React.Component {

    handleFlip = (e) => {
        e.persist();
        const card = e.target.closest('.flip-card-inner');
        if (card) {
            card.classList.toggle("transform-class"); // css class that handles transform
        }
    }

    render() {

        return (
            <div className="values-flip-section">
                <div className="row">
                    <div className="w-100 careers-heading">
                        We're Driven By Our Values
                    </div>
                    {VALUES_DATA.map((value, i) =>
                        <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3"
                            key={`career-value-${i}`} 
                            onClick={(e) => this.handleFlip(e)}>
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <div>
                                            <span className="flip-card-icon"></span>
                                            <img src={`${PUBLIC_IMG_URL}/icons/${value.icon}`} />
                                            <p className="font-weight-bold">{value.line1}</p>
                                            <p>{value.line2}</p>
                                        </div>
                                    </div>
                                    <div className="flip-card-back">
                                        <span className="flip-card-icon"></span>
                                        <p className="desc-text">{value.desc}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )

    }

}