import React from 'react';
import Select from 'react-select';
import findIndex from 'lodash/findIndex';

// constants
import { PUBLIC_IMG_URL } from '/src/lib/endpoints';

// react select styles
import { sharedConstants } from 'shared-helpers';
const reactSelectLarge = sharedConstants.reactSelectLarge;
const reactSelectTheme = sharedConstants.reactSelectTheme;

// helpers
import { renderMultiSelected, handleMultiselectChange } from '/src_temp/components/Single-SICE/reusable/CreateEditFields';

// constants
import { SAVED_TEMPLATE_TYPES } from '/src/lib/constants';

export const MapType = (props) => {

    const { state, setState, isEditPage } = props;
    const { mapType } = state;

    const mapTypes = [
        {
            name: 'startup',
            desc: 'Uncover trends and insights in the world of startups and venture funding',
            code: 'startup_market_map',
            displayName: 'Startup Market Map'
        },
        {
            name: 'investor',
            desc: 'Streamline your investment strategy, track trends and identify similar companies, all aligned with your investment thesis',
            code: 'investor_market_map',
            displayName: 'Investor Market Map'
        }
    ]

    const handleChange = (code) => {
        setState({
            mapType: code,
            // reset fields that differ between startup & investor maps
            mapSorting: null,
            mapSortIndex: null,
            mapGrouping: []
        });
    }

    return (
        <div className="col-md-12 map-select-box">
            <label>
                Market Map Type
                <span className="req"> *</span>
            </label>

            {isEditPage ?
                // edit page - a readonly field
                <>
                    {mapTypes.map((item, index) => {
                        if (mapType === item.code) {
                            return (
                                <div className="row">
                                    <div className="col-md-6" key={`map-type-${index}`}>
                                        <input value={item.displayName} disabled={true} />
                                    </div>
                                </div>
                            )
                        }
                    })}
                </>
                :
                // add page - show options
                <>
                    <div className="row">
                        {mapTypes.map((item, index) => {
                            return (
                                <div className="col-sm-6" key={`map-type-${index}`}>
                                    <div className={`map-select ${mapType === item.code ? 'active' : ''}`}
                                        onClick={() => handleChange(item.code)}>
                                        <div className="entity-icon">
                                            <img src={`${PUBLIC_IMG_URL}/icons/${item.name}.png`} />
                                        </div>
                                        <h6>{item.displayName}</h6>
                                        <p>{item.desc}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <hr className="hr-2" />
                </>
            }
        </div>
    )

}

export const MarketMapName = (props) => {
    const { state, handleChange } = props;
    const code = 'marketMapName';
    return (
        <form className="col-md-6" autoComplete="off">
            <label>
                Market Map Name
                <span className="req"> *</span>
            </label>
            <input name={code} type="text" value={state[code]} onChange={(event) => handleChange(event, code, 'market map name')} />
        </form>
    )
}

export const groupingOptions = {
    startup: [
        { name: 'Country', id: 1 },
        { name: 'Primary Industry', id: 2 },
        { name: 'Sub Industry', id: 3 },
        { name: 'Business Type', id: 4 },
        { name: 'Last Stage Raised', id: 5 }
    ],
    investor: [
        { name: 'Investor Country', id: 1 },
        { name: 'Investor Type', id: 2 },
        { name: 'Investment Country', id: 3 },
        { name: 'Investment Industry', id: 4 },
        { name: 'Investment Stage', id: 5 }
    ]
}

export const MapGrouping = (props) => {

    const { state, setState } = props;
    const { mapGrouping, mapType } = state;
    const code = 'mapGrouping';

    let options = [];
    let groupingOps = (mapType === SAVED_TEMPLATE_TYPES.marketMapStartup) ? groupingOptions.startup : groupingOptions.investor;
    groupingOps.map((item, index) => options.push({ label: item.name, value: item.id }));

    const multiselectCallback = (option) => {
        if (option) {
            let ind = state.errorMessages.indexOf('map grouping')
            if (ind > -1) {
                state.errorMessages.splice(ind, 1)
            }
        }

        setState({
            errorMessages: state.errorMessages,
            mapGrouping_error: false
        });
    }

    const deleteMultiselectItem = (name, value) => {
        let data = state[name];
        const index = findIndex(data, { itemId: value.itemId });

        if (index > -1) {
            data = data.filter(item => item.itemId !== value.itemId);
            let obj = {}; obj[name] = data; setState(obj);
        }
    }

    return (
        <div className="col-md-6">
            <label>
                Map Grouping
                <span className="req"> *</span>
                <br className="p-0" />
                <span className="text-muted">
                    (Group startups by country, primary industry, etc.)
                </span>
            </label>
            <Select
                // config
                cacheOptions
                options={options}
                // functions
                onChange={(e) => handleMultiselectChange(e, code, props, multiselectCallback)}
                // input
                placeholder="Select Map Grouping"
                value="Select Map Grouping"
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
            {renderMultiSelected(mapGrouping, 'mapGrouping', deleteMultiselectItem)}
        </div>
    )

}

export const sortingOptions = {
    startup: [
        {
            name: 'Recently Funded',
            order: 'desc',
            field: 'last_date_raised',
            id: 1
        },
        {
            name: 'Total Funding: High to Low',
            order: 'desc',
            field: 'total_fund',
            id: 2
        },
        {
            name: 'Total Funding: Low to High',
            order: 'asc',
            field: 'total_fund',
            id: 3
        }
    ],
    investor: [
        {
            name: 'No. of Investments: High to Low',
            order: 'desc',
            field: 'number_of_investments',
            id: 1
        }
    ]
}

export const MapSorting = (props) => {

    const { state, setState } = props;
    const { mapType } = state;
    let options = [];
    let sortingOps = (mapType === SAVED_TEMPLATE_TYPES.marketMapStartup) ? sortingOptions.startup : sortingOptions.investor;
    sortingOps.map((item, index) => options.push({ label: item.name, value: item.id }));

    const handleChange = (option) => {
        const sort = sortingOps[option.value - 1];
        const mapSorting = [{ key: sort.field, sortVal: sort.order }];

        if (mapSorting) {
            let ind = state.errorMessages.indexOf('map sorting')
            if (ind > -1)
                state.errorMessages.splice(ind, 1)
        }

        setState({
            mapSorting,
            mapSortIndex: option, // used to show user's selected option in input
            errorMessages: state.errorMessages,
            mapSorting_error: false,
            hasUnsavedChanges: true
        });
    }

    return (
        <div className="col-md-6">
            <label>
                Map Sorting
                <span className="req"> *</span>
                <br className="p-0" />
                <span className="text-muted">
                    {(mapType === "startup_market_map") ?
                        '(Sort startups by total funding, etc.)' : '(Sort investments by funding amount, etc.)'}
                </span>
            </label>
            <Select
                // config
                cacheOptions
                options={options}
                // functions
                onChange={handleChange}
                // input
                name="mapSorting"
                placeholder="Select Sort Type"
                value={state.mapSortIndex}
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
        </div>
    )

}
