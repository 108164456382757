import { combineReducers } from 'redux'
import fundingRoundReducer from './fundingRoundReducer'
import userReducer from './userReducer'
import profileCountReducer from './profileCountReducer'

export default combineReducers({
    userReducer,
    fundingRoundReducer,
    profileCountReducer
})
