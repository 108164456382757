import isEmpty from "lodash/isEmpty"



/* redux login / logout actions */

export const logout = () => dispatch => {
    window.localStorage.setItem("loggedIn", false);
    dispatch({ type: 'LOGOUT' })
}

export const hideLoginTab = () => dispatch => {
    dispatch({ type: 'HIDE_LOGIN_TAB' })
}

export const showLoginTab = () => dispatch => {
    dispatch({ type: 'SHOW_LOGIN_TAB' })
}

/* redux 'set' actions */

export const setUserInfo = data => (dispatch, getState) => {
    if (isEmpty(data)) {
        return
    }

    let profilePic = null;
    if (data.user.profile_pic_data && data.user.profile_pic_data.path) {
        profilePic = data.user.profile_pic_data.path;
    } else {
        profilePic = getState().userReducer.profile_pic;
    }

    window.localStorage.setItem("loggedIn", true);

    dispatch({
        type: 'SET_USER_INFO',
        refresh_token: data.refresh_token,
        isloggedin: true,
        username: data.user.username,
        fullname: `${data.user.first_name} ${data.user.last_name}`,
        id: data.user.id,
        pic: profilePic,
        email: data.user.email !== null ? data.user.email : '',
        roles: data.user.roles !== null ? data.user.roles : '',
        email_status: data.user.status !== null ? data.user.status : ''
    })
}

export const setNewToken = data => dispatch => {
    if (data !== '')
        dispatch({
            type: 'SET_NEW_TOKEN',
            token: data.token
        })
}

export const setUserRoles = data => dispatch => {
    dispatch({
        type: 'SET_USER_ROLES',
        roles: data
    })
}

export const setUserProfilePic = data => dispatch => {
    dispatch({
        type: 'SET_USER_PROFILE_PIC',
        pic: data
    })
}

export const setUserEmailStatus = data => dispatch => {
    dispatch({
        type: 'SET_USER_STATUS',
        status: data
    })
}

export const setUserSelectedReport = data => dispatch => {
    dispatch({
        type: 'SET_SELECTED_REPORT',
        selectedReportId: data
    })
}

/* redux 'select' actions */

// selector function to get user details
export const selectUserEmail = state => state.userReducer.email;
export const selectUserId    = state => state.userReducer.user_id;