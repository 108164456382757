import axios from 'axios';
import {
    v4 as uuidv4
} from 'uuid';

import { SSR_API_URL_NEW } from './endpoints';
import { logUserRequest, logResponse, logError } from './logger';

// This axios instance should only be used on the server side only
export default class AxiosServerInstance {
    constructor(userToken = "", refreshToken = "") {
        const axiosInstance = axios.create();

        this.userToken = userToken;
        this.refreshToken = refreshToken;
        this.isRefreshing = false;
        this.refreshingCall = null;
        this.axiosInstance = axiosInstance;
        this.reqId = uuidv4();
    }

    // request interceptor
    req = () => {
        let tokenValue = '';
        if (this.userToken) {
            tokenValue = this.userToken;
        }

        const reqID = this.reqId;

        this.axiosInstance.interceptors.request.use((config) => {
            if (!config.headers.Authorization) {
                if (tokenValue) {
                    config.headers.Authorization = tokenValue
                }
            }

            logUserRequest({...config}, tokenValue, reqID);

            return config;
        }, function (error) {
            logError({...error}, this.userToken, reqID);
            return Promise.reject(error);
        });

        
    }

    // response interceptor
    res = () => {
        const reqID = this.reqId;

        this.axiosInstance.interceptors.response.use(response => {
            logResponse({...response}, this.userToken, reqID);
            return response;

        }, error => {
            const originalRequest = error.config;
            const status = error.response ? error.response.status : null;

            logError({...error}, this.userToken, reqID);

            if (status === 401 && !originalRequest._retry) {
                // set retry flag to avoid a loop
                originalRequest._retry = true;

                if (error.response.config.url == `${SSR_API_URL_NEW}/auth/refresh`) {
                    return axios.get(originalRequest.url);
                } else {
                    return this.handleRefreshToken()
                        .then(token => {
                            if (token) {
                                originalRequest.headers['Authorization'] = token;
                                return this.axiosInstance.request(originalRequest);
                            } else {
                                return axios.get(originalRequest.url);
                            }
                        })
                        .catch(error => {
                            return axios.get(originalRequest.url);
                        });
                }
            }
            else if (status === 403 && !originalRequest._retry) {
                // set retry flag to avoid a loop
                originalRequest._retry = true;

                return this.handleRefreshToken()
                    .then(token => {
                        if (token) {
                            originalRequest.headers['Authorization'] = token;
                            return this.axiosInstance.request(originalRequest);
                        } else {
                            return axios.get(originalRequest.url);
                        }
                    })
                    .catch(error => {
                        return axios.get(originalRequest.url);
                    });
            }

            return {};
        });
    }

    handleRefreshToken = () => {
        if (this.isRefreshing) {
            return this.refreshingCall;
        }

        this.isRefreshing = true;
        const headers = { 'x-refresh-token': this.refreshToken };

        const refreshingCallPromise = this.axiosInstance.get(`${SSR_API_URL_NEW}/auth/refresh`, { headers })
            .then(data => {
                if (data && data.data.status == 200) {
                    this.isRefreshing = false;
                    this.refreshingCall = null;
                    this.userToken = data.data.data.access_token;

                    return Promise.resolve(data.data.data.access_token);
                } else {
                    return Promise.resolve("");
                }
            })
            .catch(err => {
                return Promise.resolve("");
            });

        this.refreshingCall = refreshingCallPromise;

        return refreshingCallPromise;
    }
}