import React from 'react';
import { CLEAR_ALL_FILTER_BTN_CLASSNAME } from '../../../../lib/constants';

const ClearFilters = ({clearFilter}) => {
    return (
        <div className="clear-filters-box">
            <h6 className="text-center">No results were found, please change your filters and try again</h6>
            <a className={`seeall mb-0 ${CLEAR_ALL_FILTER_BTN_CLASSNAME}`} onClick={event => clearFilter(event)}>
                Reset Filters
            </a>
        </div>
    );
}

export default ClearFilters;
