import React from 'react';
import loadable from '@loadable/component';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { sharedLoggerApi } from 'shared-helpers';

// api
import { customMarketMapApi } from '/src/apis/customMarketMapApi';

// constant
import { DEF_MAGNITT_DESCRIPTION, SAVED_TEMPLATE_TYPES } from '/src/lib/constants';
import { formFields } from './constants/formFields';
import { SOMETHING_WENT_WRONG } from '/src/lib/messages';

// reusable
import AddDefaultComponent from '/src_temp/components/Add/AddDefaultComponent';
import MagnittLoader from '/src/micro-frontends/MagnittLoader';
const SuccessPopup = loadable(() => import(/* webpackChunkName: "SuccessPopup" */ '/src/components/reusable/SuccessPopup'));
const ErrorPopup = loadable(() => import(/* webpackChunkName: "ErrorPopup" */ '/src/components/reusable/ErrorPopup'));
const InfoPopup = loadable(() => import(/* webpackChunkName: "InfoPopup" */ '/src/components/reusable/InfoPopup'));
const SelectedMapOptions = loadable(() => import(/* webpackChunkName: "SelectedMapOptions" */ './helpers/SelectedMapOptions'));

// function
import * as CustomMarketMapHelpers from './helpers/MarketMapHelpers'
import * as editHelper from '/src_temp/components/Single-SICE/helpers/editHelper.js';
import SegmentHelperClass from '/src/lib/segmentHelper';



const loggerApi = sharedLoggerApi;
const SegmentHelper = new SegmentHelperClass();

class AddEditCustomMap extends AddDefaultComponent {

    constructor(props) {
        super(props);

        this.state = {
            formPages: formFields,
            errorMessages: [],
            isSaving: false,
            errorMsg: SOMETHING_WENT_WRONG,
            isLoading: this.props.isEditPage ? true : false,
            hasUnsavedChanges: false,

            // load required fields from 1st page of the form
            reqFields: formFields[0].reqFields,

            // form fields
            marketMapName: '',
            countryArr: [],
            regionArr: [],
            focusArr: [],
            mapSortIndex: null,
            mapGrouping: [],
            mapType: SAVED_TEMPLATE_TYPES.marketMapStartup // default
        }

        // get id from url
        const { isEditPage, match } = this.props;
        if (isEditPage) {
            this.mapId = match.params.id;
        }

        // bind this to AddDefaultComponent functions
        this.handleChange = this.handleChange.bind(this);
        this.mapToState = this.mapToState.bind(this);

    }

    componentDidMount = () => {
        this.getMapData();
        window.addEventListener("beforeunload", this.watchTabClose);
    }

    componentWillUnmount = () => {
        window.removeEventListener("beforeunload", this.watchTabClose);
    }

    watchTabClose = (e) => {
        const { hasUnsavedChanges } = this.state;
        if (hasUnsavedChanges) {
            // note: most browsers will ignore this message and display their own version of it
            const msg = "You haven't saved your changes. Are you sure you want to close this tab?";
            (e || window.event).returnValue = msg; // Gecko + IE
            return msg;                            // Webkit, Safari, Chrome
        }
    }

    // getMapData
    // 1 - get map data based on id, then decode filters
    // 2 - check map belongs to logged in user
    getMapData = async () => {
        const { isEditPage, user } = this.props;
        if (isEditPage) {
            try {
                await CustomMarketMapHelpers.getMapData(this.mapId, this.decodeSavedFilters, user.user_id).then(
                    res => {
                        if (res.mapOwner === user.user_id) {
                            this.mapToState(res);
                        } else {
                            window.location.href = '/market-maps';
                        }
                        this.setState({ isLoading: false });
                    }
                )
            } catch (error) {
                this.setState({ isLoading: false });
                window.location.href = "/market-maps";
            }
        }
    }

    // decode saved filter (string) to object with id & name
    decodeSavedFilters = (filters) => {

        // check taxonomy lists have loaded
        const { countryList, regionsList, industryFocus } = this.state;
        let hasTaxoList = ((countryList && countryList.length > 0) && (regionsList && regionsList.length > 0) && (industryFocus && industryFocus.length > 0));

        if (hasTaxoList) {
            const decode = CustomMarketMapHelpers.decodeMapFilters(filters, this.state);
            const countryArr = decode.country;
            const focusArr = decode.industry;
            const regionArr = decode.region;
            this.setState({
                countryArr, regionArr, focusArr
            })
        } else {
            setTimeout(() => {
                this.decodeSavedFilters(filters)
            }, 100);
        }

    }

    changeFormPage = (i) => {
        const data = editHelper.changeFormPage(i, this.state, this.validateFields);
        if (data) {
            const { state, formPages, reqFields } = data;
            this.setState(
                { ...state, formPages, reqFields }, () => {
                    CustomMarketMapHelpers.handleRemoveTooltip();

                    const fromTop = document.getElementById('form-top');
                    if (fromTop) {
                        fromTop.scrollIntoView();
                    }
                }
            );
        }
    }

    validateFields = () => {

        const { reqFields, errorMessages } = this.state;
        let error_count = 0;

        const addErrorFields = (item, ind) => {
            this.state[`${item.name}_error`] = true
            error_count = error_count + 1
            if (ind === -1) {
                if (item.msg) {
                    this.state.errorMessages.push(item.msg)
                }
            }
        }

        reqFields.map((item) => {
            let ind = errorMessages.indexOf(item.msg);
            let fieldIsInvalid = (this.state[item.name] === '' || this.state[item.name] === undefined || this.state[item.name] === null || (this.state[item.name] === 'multi' && this.state[`${item.name}Arr`].length === 0));

            if (item.name === 'mapGrouping' && this.state[item.name] && this.state[item.name].length < 1) {
                fieldIsInvalid = true;
            }

            if (fieldIsInvalid) {
                addErrorFields(item, ind);
            } else {
                this.state[`${item.name}_error`] = false
                if (ind > -1) {
                    this.state.errorMessages.splice(ind, 1)
                }
            }
        })
        this.setState(this.state);

        if (!error_count > 0) {
            this.setState({ errorMessages: [] })
        }
        return (!error_count > 0);

    }

    submitForm = () => {

        CustomMarketMapHelpers.handleRemoveTooltip();

        const { isSaving, marketMapName, regionArr, countryArr, focusArr, mapGrouping, mapSorting, mapType } = this.state;
        const { user, isEditPage } = this.props;

        if (isSaving) { return }

        if (this.validateFields()) {
            this.setState({ isSaving: true });
            const filter = {
                country: countryArr, region: regionArr, industry: focusArr
            }

            let map_data = {
                name: marketMapName,
                userId: user.user_id,
                sortBy: mapSorting,
                groupBy: mapGrouping,
                filter: CustomMarketMapHelpers.prepareMapFilters(filter, mapType),
                mapType
            }

            let handleSubmit = customMarketMapApi.createCustomMap;
            if (isEditPage) {
                map_data.id = this.mapId;
                handleSubmit = customMarketMapApi.editCustomMap;
            }

            let trackStr = `Custom Market Map - ${isEditPage ? 'Edited' : 'Created'}`;
            SegmentHelper.trackEvent(trackStr);

            handleSubmit(map_data).then(
                response => {
                    if (response.status === 201 || response.status === 200) {
                        let res = response.data;
                        this.mapId = res.data.id;
                        this.handleSuccessPopup(res);
                        this.setState({
                            isSaving: false,
                            hasUnsavedChanges: false
                        });
                    } else {
                        this.handleSubmitError(response);
                    }
                }
            )

        }

    }

    getRegionLocations = async (operationType, region_id) => {
        await CustomMarketMapHelpers.getRegionLocations(this.state, operationType, region_id).then(
            response => {
                const { countryArr, locationsInRegion } = response;
                this.setState({ countryArr, locationsInRegion });
            }
        )
    }

    handleSuccessPopup = (response) => {
        this.handleSuccessRes([response], {});
    }

    handleRedirectAfterSuccess = () => {
        window.location.href = `/market-maps/${this.mapId}`;
    }

    handleRedirectAfterClose = () => {
        window.location.href = '/market-maps';
    }

    handleSubmitError = (error) => {

        let msg = "Undefined error";
        try {
            msg = error.response.data.errors[0].message;
        } catch (err) { console.log(err) }

        let errorMsg = SOMETHING_WENT_WRONG;

        // handle 'no changes updated'
        if (msg === 'No changes updated' || msg === 'No changes to update') {
            errorMsg = "The market map wasn't updated as no changes were made.";
            this.setState({ errorMsg });

            $('#infoModal').modal({
                backdrop: 'static', keyboard: true, show: true
            })
        }
        // handle - other error cases
        else {
            const { user } = this.props;
            const component = 'Add/Edit Custom Market Map';
            loggerApi.logError(user.user_id, component, error);
            errorMsg += `. (Error code: ${msg})`;
            this.setState({ errorMsg });

            $('#errorModal').modal({
                backdrop: 'static', keyboard: true, show: true
            });
        }

        this.setState({ isSaving: false });

    }

    renderActionButtons = (currentPage, isPreviewPage) => {

        const { isAddPage } = this.props;

        return (
            <div className="row create-edit-btns">

                {(currentPage.id > 0) &&
                    <div className="col-sm-6 mb-4 mb-sm-0">
                        <div className="btn btn-main-outline btn-block" onClick={() => this.changeFormPage(parseInt(currentPage.id) - 1)}>
                            <i className="fas fa-arrow-circle-left"></i>
                            Edit Map
                        </div>
                    </div>
                }

                <div className="col-sm-6">
                    {!isPreviewPage ?
                        <div className="btn btn-main btn-block" onClick={() => this.changeFormPage(parseInt(currentPage.id) + 1)}>
                            <i className="fas fa-arrow-circle-right"></i>
                            Check Map Preview
                        </div>
                        :
                        <div className="btn btn-main btn-block" onClick={() => this.submitForm()}>
                            {this.state.isSaving ?
                                <i className="fas fa-circle-notch fa-spin"></i>
                                :
                                <i className="fas fa-check-circle"></i>
                            }
                            {isAddPage ? `Create Market Map` : 'Save Changes'}
                        </div>
                    }
                </div>

            </div>
        )
    }

    render() {

        const { isAddPage, user, isEditPage } = this.props;
        const { formPages, errorMessages, isLoading, mapGrouping, mapSortIndex, mapType } = this.state;
        const pageTitle = `${isAddPage ? 'Create' : 'Edit'} Custom Market Map`;

        // handle logged out case
        if (!user.is_loggedin) {
            return (<Redirect to="/market-maps" />);
        }

        // form pages
        let currentPage;
        formPages.map((page) => {
            if (page.visible) {
                currentPage = page;
            }
        })

        // paging
        const isPreviewPage = (parseInt(currentPage.id) === (formPages.length - 1));

        // selected filters for map preview
        const filterArray = {
            region: this.state.regionArr,
            country: this.state.countryArr,
            industry: this.state.focusArr
        };

        const selectedFilters = CustomMarketMapHelpers.prepareMapFilters(filterArray, mapType);

        return (

            <div className="footer-margin-offset">

                <Helmet
                    title={`${pageTitle} | MAGNiTT`}
                    meta={[
                        { name: 'description', content: DEF_MAGNITT_DESCRIPTION },
                        { name: 'abstract', content: DEF_MAGNITT_DESCRIPTION },
                        { property: 'rating', content: 'general' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: pageTitle },
                    ]}
                />

                <section className="directory content-box single-sice">
                    <div className="content-block">
                        <div className="row create-edit-form" id="form-top">

                            {/* form box */}
                            <div className={isPreviewPage ? 'col' : "col-lg-12 col-xl-9"}>

                                <div className="mid-width-info-box">
                                    {isLoading ?
                                        <MagnittLoader data={{ height: "300px", background: "transparent" }} />
                                        :
                                        <div className="info-box-container">

                                            <div className="db-header">
                                                <div>{pageTitle}</div>
                                                <p>{currentPage.step}: {currentPage.name}</p>
                                            </div>

                                            <div className={`info-box-body ${isPreviewPage ? 'preview-box' : ''}`}>

                                                {/* if preview page, render the buttons at page top as well */}
                                                {isPreviewPage &&
                                                    <>{this.renderActionButtons(currentPage, isPreviewPage)}</>
                                                }

                                                {/* reusable form fields */}
                                                <div className="form-box-layout">

                                                    {isPreviewPage && <SelectedMapOptions mapFilters={selectedFilters} mapSorting={mapSortIndex} mapGrouping={mapGrouping} mapType={mapType} />}

                                                    <div className={isPreviewPage ? '' : "row"}>
                                                        {currentPage.content.map((item, i) => {
                                                            const Element = item;
                                                            return (
                                                                <Element
                                                                    key={i}

                                                                    // pass functions
                                                                    handleChange={this.handleChange}
                                                                    removeEditMult={this.removeEditMult}
                                                                    getRegionLocations={this.getRegionLocations}

                                                                    // pass component functions/state
                                                                    setState={(s, c) => { this.setState(s, c) }}
                                                                    state={this.state}
                                                                    isEditPage={isEditPage}

                                                                    // for market map preview
                                                                    view='preview'
                                                                    user={this.props.user}

                                                                />
                                                            )
                                                        })}
                                                    </div>
                                                </div>

                                                {/* form feedback */}
                                                <div className="form-feedback-container">
                                                    {(errorMessages.length > 0) &&
                                                        <div>
                                                            {'Please fill the following fields'}:{" "}
                                                            {errorMessages.map((err, i) => {
                                                                let error = err;
                                                                if (errorMessages.length - 1 !== i) {
                                                                    error += ", "
                                                                }
                                                                return error;
                                                            })}
                                                        </div>
                                                    }
                                                </div>

                                                {this.renderActionButtons(currentPage, isPreviewPage)}

                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                {/* modals */}
                <SuccessPopup
                    msg={<>The custom market map has been {isAddPage ? "created" : "updated"} successfully</>}
                    handleClose={this.handleRedirectAfterClose}
                    // button
                    showButton={true}
                    buttonText={`View Custom Market Map`}
                    buttonAction={this.handleRedirectAfterSuccess}
                />

                <ErrorPopup msg={this.state.errorMsg} />
                <InfoPopup msg={this.state.errorMsg} />

            </div>

        )
    }

}

const mapStateToProps = (state) => ({
    user: state.userReducer
})

export default connect(mapStateToProps)(withRouter(AddEditCustomMap));