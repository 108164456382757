import * as jwt_decode from 'jwt-decode';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { modalHelpers } from 'shared-helpers';

// constants
import { API_URL_NEW } from './endpoints';
import { COOKIE_NAME } from './constants';
import { ERROR_MESSAGES } from './errorMessages';

// reusable
import toast from '../components/reusable/toast';



const cookies = new Cookies;

export default class Interceptor {
    constructor(reduxStore) {
        this.isRefreshing = false;
        this.refreshingCall = null;
        this.refreshToken = null;
        this.reduxStore = reduxStore;

        this.setRefreshToken();
    }

    setRefreshToken = () => {
        this.refreshToken = this.reduxStore.getState().userReducer.refresh_token;
    }

    handleLogout = () => {
        modalHelpers.showForceLogoutModal();
    }

    updateData = (token, roles, status) => {
        this.reduxStore.dispatch({ type: 'SET_NEW_TOKEN', token });
        this.reduxStore.dispatch({ type: 'SET_USER_ROLES', roles });
        this.reduxStore.dispatch({ type: 'SET_USER_STATUS', status });
    }

    req = () => {
        axios.interceptors.request.use(function (config) {
            if (!config.headers.Authorization) {
                let tokenValue = '';
                if (cookies.get(COOKIE_NAME) && cookies.get(COOKIE_NAME).userReducer && cookies.get(COOKIE_NAME).userReducer.token) {
                    tokenValue = cookies.get(COOKIE_NAME).userReducer.token;
                }

                if (tokenValue) {
                    config.headers.Authorization = tokenValue;
                }
            }

            return config
        }, function (error) {
            return Promise.reject(error)
        });
    }

    /**
     * This method will call the refresh token API and will set the token wherever it's needed
     * Once the API is called, @isRefreshing flag will be set to true and @refreshingCall will save the promise to API
     * Later, if the API is called again then it will simply return the previous call to avoid the duplicate calls
     */
    handleRefreshToken = () => {
        if (this.isRefreshing) {
            return this.refreshingCall;
        }

        this.isRefreshing = true;
        const headers = { 'x-refresh-token': this.refreshToken };

        const refreshingCallPromise = axios.get(`${API_URL_NEW}/auth/refresh`, { headers })
            .then(data => {
                if (data && data.data.status == 200) {
                    this.isRefreshing = false;
                    this.refreshingCall = null;
                    const decodedToken = jwt_decode(data.data.data.access_token)
                    this.updateData(data.data.data.access_token, decodedToken.roles, decodedToken.user_status)

                    return Promise.resolve(data);
                } else {
                    // This error will be caught at the component level
                    throw {
                        status: 401,
                        isRefreshTokenExpired: true
                    };
                }
            })
            .catch(err => {
                return Promise.reject(err);
            });

        this.refreshingCall = refreshingCallPromise;

        return refreshingCallPromise;
    }

    /**
     * Success: Return the response
     *
     * Error:
     *  status 401:
     *      if 401 from refresh token API -> logout
     *          else call refresh token API
     *
     *  status 403:
     *      invoke error toast then return the error object
     *
     * All the other status(s)
     *  return the error object
     */
    res = () => {

        axios.interceptors.response.use(response => {
            return response;
        }, 
        
        error => {

            const originalRequest = error.config;
            const status = error.response ? error.response.status : null;

            if (status === 401 && !originalRequest._retry) {
                // set retry flag to avoid a loop
                originalRequest._retry = true;

                if (error.response.config.url == `${API_URL_NEW}/auth/refresh`) {
                    return this.handleLogout();
                } else {
                    return this.handleRefreshToken()
                        .then(data => {
                            error.config.headers['Authorization'] = data.data.data.access_token;
                            return axios.request(error.config);
                        })
                        .catch(error => {
                            // todo: Here, we should not call logout on all the error, maybe only 401
                            this.handleLogout();
                            return Promise.reject(error)
                        });
                }
            }
            else if (status === 403 && !originalRequest._retry) {
                // set retry flag to avoid a loop
                originalRequest._retry = true;

                return this.handleRefreshToken()
                    .then(data => {
                        originalRequest.headers['Authorization'] = data.data.data.access_token;
                        return axios.request(originalRequest);
                    })
                    .catch(error => {
                        toast.error(ERROR_MESSAGES.UNAUTHORIZED_ERROR_MSG, "unauthorized-error-msg")
                        return Promise.reject(error)
                    });
            }

            return Promise.reject(error);
        });
    }
}
