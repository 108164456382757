import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// api
import { customMarketMapApi } from '/src/apis/customMarketMapApi';

// constants
import { ALLOWED_QUERY_OPERATOR, SAVED_TEMPLATE_TYPES } from '/src/lib/constants';

// reusable
import { DeleteMapModal } from './components/DeleteMapModal';



const mapTypes = [
    SAVED_TEMPLATE_TYPES.marketMapStartup,
    SAVED_TEMPLATE_TYPES.marketMapInvestor
];

const baseState = {
    // count = no. of maps displayed (for show more)
    // totalCount = total no. of maps
    startup: { maps: [], count: 0, totalCount: 0, page: 1 },
    investor: { maps: [], count: 0, totalCount: 0, page: 1 },
}

const PAGE_LIMIT = 12;

export const ManageCustomMarketMaps = () => {

    /* state variables */

    // data
    const [mapList, setMapList] = useState(baseState);
    const [mapToDelete, setMapToDelete] = useState(null);
    const [updateView, setUpdateView] = useState(0);

    // visibility
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletedMapIds, setDeletedMapIds] = useState([]); // used to hide deleted maps from view
    const [activeView, setActiveView] = useState('startup');
    const [showMapsLoading, setMapsLoading] = useState(false);

    const user = useSelector((state) => state.userReducer);

    /* functions */

    // To detect if user enters the page by clicking back on browser. 
    // In this case update the view to get modified data.
    useEffect(() => {
        // Document ready check to avoid 'Target container is not a DOM element' error
        if (document?.readyState !== "loading") {
            if (performance.getEntriesByType("navigation")[0].type == "back_forward") {
                updateMapView();
            }
        }
    }, []);

    useEffect(() => {
        getUserMaps('reset');
    }, [updateView]);

    const getUserMaps = (loadType, viewType) => {
        mapTypes.map((mapType, index) => {

            const filter = [{
                key: 'entity_type',
                value: [mapType],
                op: ALLOWED_QUERY_OPERATOR.equal
            }]

            const entity = mapType.split('_')[0];
            const page = (loadType === 'reset') ? 1 : mapList[entity].page;

            if (viewType && viewType !== entity) {
                return;
            }

            customMarketMapApi.getByUser(user.user_id, page, PAGE_LIMIT, filter).then(
                response => {

                    if (response.data) {
                        let temp = mapList[entity];
                        temp.page = page;
                        if (response.navigation) {
                            temp.totalCount = response.navigation.total;
                        }

                        const allResultsShown = !(response.navigation.total > (page * PAGE_LIMIT));
                        temp.count = allResultsShown ? response.navigation.total : page * PAGE_LIMIT;

                        if (temp.maps.length > 0 && loadType !== 'reset') {
                            temp.maps = temp.maps.concat(response.data)
                        } else {
                            temp.maps = response.data
                        }

                        setMapList(prevData => ({
                            ...prevData,
                            [entity]: temp
                        }));
                    }

                    if (index > 0) {
                        setMapsLoading(false);
                    }
                }
            )
        });
    }

    const showMoreMaps = () => {
        let tempData = mapList[activeView];
        tempData.page += 1;
        setMapList(prevData => ({
            ...prevData,
            [activeView]: tempData
        }));

        setMapsLoading(true);
        getUserMaps(null, activeView);
    }

    const openDeleteModal = (mapData) => {
        setMapToDelete(mapData);
        setShowDeleteModal(true);
    }

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setMapToDelete(null); // reset
    }

    /* When user clicks show more after having deleted a map one market map is missed 
    As the first item of page 2 becomes the last item of page 1. Hence we need to update the view to reload the maps */
    const updateMapView = () => {
        setMapList(baseState);
        setUpdateView(prevState => prevState + 1);
        closeDeleteModal();
    }

    const renderUserMapList = () => {

        const mapData = mapList[activeView];

        return (
            <div className="row manage-row manage-map-row">

                {mapData.maps.map((map, index) => {

                    if (deletedMapIds.includes(map.id)) {
                        return;
                    }

                    const map_link = `/market-maps/${map.id}`;

                    return (
                        <div key={`manage-map-${map.id}`}>
                            <div className="manage-btn manage-map-btn">
                                <div className="d-flex">
                                    <div>

                                        <span>
                                            <a href={map_link}>
                                                {map.name}
                                            </a>
                                        </span>

                                        {/* buttons */}

                                        <div className="btn-group" role="group" aria-label="manage-map-buttons">
                                            <a type="button" className="btn btn-sm btn-main-outline" href={map_link}>
                                                <i className="fas fa-eye"></i>
                                                View
                                            </a>
                                            <a type="button" className="btn btn-sm btn-main-outline" href={`${map_link}/edit`}>
                                                <i className="fas fa-edit"></i>
                                                Edit
                                            </a>
                                            <button type="button"
                                                className="btn btn-sm btn-main-outline"
                                                onClick={() => openDeleteModal(map)}>
                                                <i className="far fa-trash-alt"></i>
                                                Delete
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

                {(mapData.count < mapData.totalCount) &&
                    <div className="show-more mb-2">
                        <a className="btn btn-main" onClick={() => showMoreMaps()}>
                            {showMapsLoading ?
                                <i className="fas fa-circle-notch fa-spin mr-2"></i>
                                :
                                <i className="fas fa-arrow-circle-down mr-2"></i>
                            }
                            Show More
                        </a>
                    </div>
                }
            </div>
        )
    }

    return (
        <section className="directory content-box">
            <div className="content-block">
                <div className="row">

                    <div className="rightside column-general general-manage-page">
                        <div className="db-box">

                            <div className="db-header">
                                <div>Manage Custom Market Maps</div>
                            </div>

                            {/* manage buttons */}
                            <div className="row manage-row">
                                <div>
                                    <a className="manage-btn" href="/market-maps/add">
                                        <div>
                                            <i className="manage-btn-icon fas fa-plus-circle"></i>
                                            Create New Market Map
                                        </div>
                                        <i className="fas fa-chevron-right"></i>
                                    </a>
                                </div>
                                <div>
                                    <a className="manage-btn" href="/lists/meapt">
                                        <div>
                                            <i className="manage-btn-icon fas fa-globe-americas"></i>
                                            View Default Market Map
                                        </div>
                                        <i className="fas fa-chevron-right"></i>
                                    </a>
                                </div>
                            </div>

                            {/* user's created maps */}
                            <hr />
                            <div className="db-header">
                                <div>
                                    My Custom Market Maps
                                </div>
                            </div>

                            {/* tab buttons */}
                            <p className="tabbed-display">
                                {mapTypes.map((tab, i) => {

                                    const entity = tab.split('_')[0];
                                    const heading = entity[0].toUpperCase() + entity.slice(1) + ' Maps';

                                    return (
                                        <a className="btn btn-main-outline" onClick={() => setActiveView(entity)} data-active={activeView === entity} key={`tab-link-${i}`}>
                                            {heading}
                                            {(mapList[entity].count > 0) && (
                                                <span className="badge">
                                                    {mapList[entity].totalCount}
                                                </span>
                                            )}
                                        </a>
                                    )

                                })}
                            </p>

                            {(mapList[activeView].count > 0) ?
                                <>
                                    {mapList[activeView].totalCount > PAGE_LIMIT &&
                                        <p className="mb-3">
                                            Showing {mapList[activeView].count} of {mapList[activeView].totalCount} {activeView} market maps
                                        </p>
                                    }
                                    {renderUserMapList()}
                                </>
                                :
                                <p>You have not created any custom market maps for {activeView + "s"}</p>
                            }

                        </div>
                    </div>

                </div>
            </div>

            <DeleteMapModal
                // data
                mapToDelete={mapToDelete}
                userId={user.user_id}
                // func
                closeModal={closeDeleteModal}
                setDeletedMapIds={setDeletedMapIds}
                updateMapView={updateMapView}
                // visibility
                showDeleteModal={showDeleteModal}
            />

        </section>
    )
}