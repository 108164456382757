const countriesIds = {
    development: {
        UAE: 5847,
        KSA: 5812,
        GLOBAL: 18821
    },
    production: {
        UAE: 5847,
        KSA: 5812,
        GLOBAL: 6685
    }
};

export const COUNTRIES_IDS = countriesIds[process.env.NODE_ENV];