// todo: Ultimately this should come from API

export const fundraisingOptions = [
    { id: 1, name: 'No Funding', minValue: 0, maxValue: 0 },
    { id: 2, name: '$0 - 25K', minValue: 0, maxValue: 25000 },
    { id: 3, name: '$26K - 50K', minValue: 26000, maxValue: 50000 },
    { id: 4, name: '$51K - 100K', minValue: 51000, maxValue: 100000 },
    { id: 5, name: '$101K - 250K', minValue: 101000, maxValue: 250000 },
    { id: 6, name: '$251K - 500K', minValue: 251000, maxValue: 500000 },
    { id: 7, name: '$501K - 1m', minValue: 501000, maxValue: 1000000 },
    { id: 8, name: '$1m-2.5m', minValue: 1000000, maxValue: 2500000 },
    { id: 9, name: '$5m-10m', minValue: 5000000, maxValue: 10000000 },
    { id: 10, name: '$10m-25m', minValue: 10000000, maxValue: 25000000 },
    { id: 11, name: '$25m+', minValue: 25000000, maxValue: 0 }
]

export const numOfEmployees = [
    { id: 0, name: '0 - 1', minValue: 0, maxValue: 1 },
    { id: 1, name: '2 - 10', minValue: 2, maxValue: 10 },
    { id: 2, name: '11 - 50', minValue: 11, maxValue: 50 },
    { id: 3, name: '51 - 200', minValue: 51, maxValue: 200 },
    { id: 4, name: '201 - 500', minValue: 201, maxValue: 500 },
    { id: 5, name: '501 - 1000', minValue: 501, maxValue: 1000 },
    { id: 6, name: '1001 - 5000', minValue: 1001, maxValue: 5000 },
    { id: 7, name: '5001 - 10000', minValue: 5001, maxValue: 10000 },
    { id: 8, name: '10000+', minValue: 10000, maxValue: 0 },
]

// property editKey = used on editSICE to identify field where the answer is saved
export const additionalQuestions = [
    { 
        keyName: "problem_solved", 
        question: "What problem are you solving, or what value are you creating?",
        editKey: "idea2"
    },
    {
        keyName: "market_opportunity",
        question: "What's your market opportunity and how will you acquire customers?",
        editKey: "idea3"
    },
    {
        keyName: "competitors",
        question: "Who are your competitors and what makes you different?",
        editKey: "idea4"
    },
    {
        keyName: "monetization",
        question: "How will you monetize?",
        editKey: "idea5"
    },
    { 
        keyName: "challenges",
        question: "What are your top three challenges?",
        editKey: "idea6"
    }
]