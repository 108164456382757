import React from 'react';
import loadable from '@loadable/component';
import { sharedConstants } from 'shared-helpers';

// constants
import { PUBLIC_IMG_URL } from '/src/lib/endpoints';
import { CONTACT_FORM_TYPE, HUBSPOT_FORM_SOURCE } from '/src/lib/constants';

// components
const ContactForm = loadable(() => import(/* webpackChunkName: "ContactForm" */ '/src/components/contactForms/ContactForm'));



const TAXONOMIES = sharedConstants.TAXONOMIES;

export const CustomMarketMapsLandingPage = () => {

    const feature_images = [
        '/landing-pages/market-maps-1.png',
        '/landing-pages/market-maps-2.png',
        '/landing-pages/market-maps-3.png',
    ]

    return (
        <div>
            <section className="directory content-box contact-page advanced-analytics-form">
                <div className="bg-img">
                    <div className="row">

                        <div className="col-md-10 col-lg-7 col-xl-7 mx-auto mb-4 mb-lg-0">
                            <div className="db-box no-background h-100">
                                <div className="text-section">

                                    <p className="first-line red">
                                        <span>Data is only as good as the decisions it enables</span>
                                    </p>
                                    <p>Sick of wasting your time constantly updating market maps manually? We’ve got you covered. Whether you want to track trends, identify similar companies or build a tracker to match your investment thesis, MAGNiTT has the solution.</p>
                                    <p>Market maps created on demand in 4 clicks, built in no time, <strong>and easy access by saving them on your account forever!</strong> Bonus point: you can build and save as many maps as you want. What else would you need?</p>
                                    <p>Save your precious time and reach out to our team to start visualizing your segments of the startup ecosystem!</p>

                                </div>
                            </div>
                        </div>

                        <ContactForm
                            taxonomyType={TAXONOMIES.salesContactOptions}
                            formType={CONTACT_FORM_TYPE.enterprise}
                            buttonLabel='Contact Us'
                            page={HUBSPOT_FORM_SOURCE.custom_market_map}
                        />

                    </div>
                </div>

                {/* product features */}
                <div className="db-box h-100" style={{ marginTop: 30 }}>
                    <div className="stats-sub text-center">
                        <div className="row mt-4-offset">

                            {feature_images.map((img, i) =>
                                <div className="col-12 col-md-4 mt-4" key={`product-image-${i}`}>
                                    <div className="market-map-product-img">
                                        <img src={`${PUBLIC_IMG_URL}${img}`} className="rounded" />
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>

            </section>
        </div>
    )
}
