import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';

import DefaultComponent from '/src_temp/components/DirectoryTable/DefaultComponent';
import StartupMarketMapLandscape from './StartupMarketMapLandscape';
import StartupMarketMapCharts from './StartupMarketMapCharts';
import StartupMarketMapTable from './StartupMarketMapTable';

// constants and helpers
import { MAGNITT_ACCESS_ROLES } from '/src/lib/constants/userRoles';
import { WEB_URL } from '/src/lib/endpoints'
import SegmentHelperClass from '/src/lib/segmentHelper';

const SegmentHelper = new SegmentHelperClass();



class DefaultMarketMap extends DefaultComponent {
    constructor() {
        super();
        this.state = {
            page: 1,
            isLandscape: true,
            isDashboard: false,
            isTable: false,

            view: 'finalMap',
            group: [
                { name: 'Country', id: 1 },
                { name: 'Primary Industry', id: 2 },
                { name: 'Sub Industry', id: 3 },
                { name: 'Business Type', id: 4 },
                { name: 'Last Stage Raised', id: 5}
            ],
            sort: { name: 'Recently Funded', order: 'desc', field: 'last_date_raised', id: 1 },

            directoryCharts: {
                filter: [],
                limit: 9,
                refresh: 0
            },
            directoryNetworkMap: {
                filter: [],
                limit: 9,
                marketMapData: { data: [] },
                refresh: 0
            }
        };
    }

    componentDidMount() {
        const { user } = this.props;
        const hasAccess = user.is_loggedin && user.roles.includes(MAGNITT_ACCESS_ROLES.directory_access);

        if (!hasAccess) {
            window.location.replace(`${WEB_URL}/market-maps`);
        }
        this.segmentTrack('Default Market Map - Viewed');
    }

    gtag = (label) => {
        window.gtag('event', 'click', {
            'event_category': `analytics`,
            'event_label': label
        })
    };

    changeTab(currentTab) {
        this.gtag(currentTab.replace("is", ''));
        let obj = {};
        obj.isLandscape = false;
        obj.isDashboard = false;
        obj.isTable = false;
        obj[currentTab] = true;
        this.setState(obj);

        if (typeof window.jQuery !== 'undefined') {
            $(function () {
                // remove tooltips as it interferes with position of tooltips in new tab
                $(".d3-tooltip").remove();

                //remove landscape tooltips when tab is changed
                $(".popover").remove();
            })
        }
    }

    renderLandscape = () => {
        const { isLandscape, view, group, sort } = this.state;

        return (
            <div className={`${isLandscape ? "" : "d-none"}`}>
                <StartupMarketMapLandscape
                    data={this.state.directoryNetworkMap}
                    view={view}
                    group={group}
                    order={sort}
                    tabIsVisible={isLandscape}
                />
            </div>
        )
    }

    renderDashboard = () => {
        const { isDashboard, directoryCharts } = this.state;

        return (
            <div className={`${isDashboard ? "" : "d-none"}`}>
                <StartupMarketMapCharts
                    data={directoryCharts}
                    tabIsVisible={isDashboard}
                />
            </div>
        )
    }

    renderTable = () => {
        const { isTable, sort, view } = this.state;
        const { user } = this.props;
        let filter = [];

        // add display-none class to prevent componentDidMount API calls from running multiple times
        return (
            <div className={`${isTable ? "" : "d-none"}`}>
                <StartupMarketMapTable
                    view={view}
                    order={sort}
                    mapFilters={filter}
                    user={user}
                    tabIsVisible={isTable}
                />
            </div>
        )
    }

    renderTabs() {
        return (
            <div className="navigation-wrapper">
                <div onClick={() => { this.changeTab('isLandscape'); this.segmentTrack('Landscape') }} className={this.state.isLandscape ? 'activeTab' : 'nonActiveTab'}>
                    <i className="fa fa-th-large"></i>
                    Landscape
                </div>
                <div onClick={() => { this.changeTab('isDashboard'); this.segmentTrack('Trends') }} className={`hidden-md-down ${this.state.isDashboard ? 'activeTab' : 'nonActiveTab'}`}>
                    <i className="fa fa-chart-line"></i>
                    Trends
                </div>
                <div onClick={() => { this.changeTab('isTable'); this.segmentTrack('Table') }} className={this.state.isTable ? 'activeTab' : 'nonActiveTab'}>
                    <i className="fa fa-table"></i>
                    Table
                </div>
            </div>
        );
    }

    segmentTrack(action) {
        SegmentHelper.trackEvent(action);
    }

    Login(event) {
        const headerLogin = document.getElementById('headerLogin');
        if (headerLogin) {
            headerLogin.click();
        }
    }

    Register(event) {
        const headerRegister = document.getElementById('headerRegister');
        if (headerRegister) {
            headerRegister.click();
        }
    }

    render() {

        // constants
        const description = 'A collection of venture backed startups categorized based on different factors such as geography, industry etc.'

        return (
            <div className="directory content-box footer-margin-offset">

                <Helmet
                    title={'MEAPT Startup Market Map | MAGNiTT'}
                    link={[
                        {
                            rel: 'canonical',
                            href: `${WEB_URL}/lists/meapt`
                        }
                    ]}
                    meta={[
                        {
                            name: 'description',
                            content: description
                        },
                        {
                            name: 'abstract',
                            content: description
                        },
                        {
                            property: 'og:type',
                            content: 'article'
                        },
                        {
                            property: 'og:url',
                            content: `${WEB_URL}/lists/meapt`
                        },
                        {
                            property: 'og:title',
                            content: 'MEAPT Market Map | MAGNiTT'
                        }
                    ]}
                    script={[
                        {
                            'type': 'application/ld+json',
                            'innerHTML': `{
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [{
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "MEAPT Market Map",
                                    "item": "${WEB_URL}/lists/meapt"
                                }]
                            }`
                        }
                    ]}
                />

                <div className="create-edit-form">
                    <div className="mid-width-info-box">
                        <div className="info-box-container">
                            <div className="db-header market-map-header">
                                <div>
                                    Default Startup Market Map <span className="text-muted">(MEAPT Venture Landscape)</span>
                                    <br />
                                    <p>
                                        This market map highlights all funded startups in the MEAPT region (Middle East, Africa, Pakistan and Turkey). Discover the trends of startups and venture funding in realtime.
                                    </p>
                                </div>
                                <div>
                                    <a className="btn btn-sm btn-main-outline" href="/market-maps">
                                        <i className="fas fa-globe-americas"></i>
                                        View All Maps
                                    </a>
                                </div>
                            </div>

                            <div className="info-box-body preview-box">
                                <div className="form-box-layout">
                                    <section className="content-box network-map-main preview-market-map">
                                        <div>
                                            <div className="network-map-navigation">
                                                {this.renderTabs()}
                                            </div>

                                            {this.renderLandscape()}
                                            {this.renderDashboard()}
                                            {this.renderTable()}
                                        </div>
                                    </section>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        )

    }
}

const mapStateToProps = (state) => ({
    user: state.userReducer
})

export default connect(mapStateToProps, null)(withRouter(DefaultMarketMap));