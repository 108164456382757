import React from 'react'
import { Helmet } from 'react-helmet';
import { sharedEndpoints } from 'shared-helpers';
import MicroFrontend from "../MicroFrontend"

const WEB_URL = sharedEndpoints.WEB_URL;

const FeatureUnavailable = ({ history }) => {
    return <>
        <Helmet
            title="Feature no longer exist"
            link={[
                {
                    rel: 'canonical',
                    href: `${WEB_URL}/feature-unavailable`,
                },
            ]}
            meta={[
                {
                    name: 'description',
                    content: 'Sorry, this feature no longer exists',
                },
                {
                    name: 'abstract',
                    content: 'Sorry, this feature no longer exists',
                },
                {
                    property: 'og:url',
                    content: `${WEB_URL}/feature-unavailable`,
                },
                {
                    property: 'og:title',
                    content: 'Feature no longer exist',
                },
            ]}
            script={[
                {
                    type: 'application/ld+json',
                    innerHTML: `{
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [{
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "404 page",
                                    "item": "${WEB_URL}/feature-unavailable"
                                }]
                            }`,
                },
            ]}
        />
        <MicroFrontend
            history={history}
            name="FeatureUnavailable"
        />
    </>
}

export default (FeatureUnavailable);