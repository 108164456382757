import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';

// api
import jobApi from '/src/apis/jobApi';
import magnittDepartmentApi from '/src/apis/magnittDepartmentApi';

// constants
import { PUBLIC_IMG_URL } from '/src/lib/endpoints';
import { COUNTRIES_IDS } from '/src/lib/constants/countries';

// helpers
import { getItemWebUrl } from '/src/lib/helpers';



class OpenPositions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            jobsList: [],
            departments: [],
            filteredJobs: [],

            // filters
            selectedDep: "All Departments",
            selectedCountry: "All Countries"
        }
        this.displayType = props.jobType;
    }

    componentDidMount = () => {
        this.getDepartmentsList();
    }

    getDepartmentsList = () => {
        magnittDepartmentApi.getDepartments().then(
            response => {
                if (!isEmpty(response)) {
                    let departments = response.records;
                    this.setState({ departments });
                    this.getJobsList();
                }
            }
        )
    }

    // since there aren't many jobs posted, all jobs are fetched in the beginning
    // any filtering by department / location is only on the front-end
    getJobsList = () => {
        jobApi.getJobsData(this.displayType).then(
            response => {
                if (!isEmpty(response)) {
                    const data = response.records;
                    this.setState({
                        jobsList: data,
                        filteredJobs: data
                    });
                    this.getDepartmentName(data);
                }
            }
        )
    }

    getDepartmentName = (jobs) => {
        const { departments } = this.state;
        let temp = jobs;
        temp.map((item, i) => {
            temp[i].department_name = "";
            departments.map((dep, j) => {
                if (dep.id === item.department_id) {
                    temp[i].department_name = dep.name;
                }
            })
        })

        temp = temp.filter((item) => item.department_name != "")
        this.setState({ jobsList: temp, filteredJobs: temp, departments });
    }

    renderDepartmentIcon = (dep) => {
        let icon = "laptop.svg"; // default

        if (dep) {
            if (dep.includes("Human Resources")) {
                icon = "user-search.svg";
            } else if (dep.includes("Tech") || dep.includes("Product") || dep.includes("Engineering")) {
                icon = "cpu-setting.svg";
            } else if (dep.includes("Business")) {
                icon = "dollar-circle.svg";
            } else if (dep.includes("Research")) {
                icon = "global-search.svg";
            } else if (dep.includes("Data")) {
                icon = "graph.svg";
            } else if (dep.includes("Marketing")) {
                icon = "image.svg";
            } else if (dep.includes("Operations")) {
                icon = "setting.svg";
            }
        }

        return (
            <img src={`${PUBLIC_IMG_URL}/icons/${icon}`} />
        );
    }

    handleFilter = (filterType, selected) => {
        let { selectedDep, selectedCountry } = this.state;
        if (filterType === 'dep') {
            selectedDep = selected;
        } else if (filterType === "loc") {
            selectedCountry = selected;
        }

        // handle dep
        let jobs = this.state.jobsList;
        if (!selectedDep.includes("All")) {
            jobs = jobs.filter(job => job.department_name === selectedDep);
        }

        // handle loc
        if (!selectedCountry.includes("All")) {
            jobs = jobs.filter((job, index) => {
                const hasLocationData = (job.location_data && job.location_data.id);
                if (hasLocationData) {
                    if (selectedCountry === "Remote") {
                        return job.location_data.id === COUNTRIES_IDS.GLOBAL;
                    } else if (selectedCountry === "UAE") {
                        return job.location_data.id === COUNTRIES_IDS.UAE;
                    }
                }
            });
        }

        this.setState({
            selectedDep,
            selectedCountry,
            filteredJobs: jobs
        })
    }

    /* department filter is only displayed for careers page, not internships page */
    renderDepartmentFilters = () => {
        const { displayType } = this;
        const { departments, selectedDep } = this.state;
        const isCareersPage = (displayType === "jobs");

        if (isCareersPage) {
            return (
                <>
                    <div className="col-auto">
                        <div className="job-filter"
                            data-active={selectedDep === "All Departments"}
                            onClick={() => this.handleFilter('dep', "All Departments")}>
                            All Departments
                        </div>
                    </div>
                    {departments.map((dep, i) =>
                        <div className="col-auto" key={`dep-${i}`}>
                            <div className="job-filter"
                                data-active={selectedDep === dep.name}
                                onClick={() => this.handleFilter('dep', dep.name)}>
                                {dep.name}
                            </div>
                        </div>
                    )}
                    <div className="filter-divider"></div>
                </>
            )
        }
    }

    renderCountryFilters = () => {
        const { selectedCountry } = this.state;
        const countryFilter = [
            "All Countries", "UAE", "Remote"
        ];
        return (
            countryFilter.map((filt, i) =>
                <div className="col-auto" key={`country-filt-${i}`}>
                    <div className="job-filter"
                        data-active={selectedCountry === filt}
                        onClick={() => this.handleFilter('loc', filt)}>
                        {filt}
                    </div>
                </div>
            )
        );
    }

    render() {

        const { filteredJobs } = this.state;
        const { displayType } = this;
        const isCareersPage = (displayType === "jobs");

        return (
            <div className="careers-jobs-section" id="open-positions">

                <div className="careers-heading text-center">
                    {isCareersPage ? "Open Positions" : "Our Internships"}
                </div>

                <div className="row job-types">
                    {this.renderDepartmentFilters()}
                    {this.renderCountryFilters()}
                </div>

                <div className="row">
                    {filteredJobs.map((job, i) => {

                        let country = "No Location Specified";
                        if (job.location_data && job.location_data.name) {
                            country = job.location_data.name;
                        }
                        if (country === "Global") {
                            country = "Remote"; // 'global' country is used to manage remote jobs
                        }

                        return (
                            <div className="col-md-6 col-lg-4" key={`job-${i}`}>
                                <a className="job-item" href={`/${getItemWebUrl('jobs', job.id, job.code)}`}>
                                    {this.renderDepartmentIcon(job.department_name)}
                                    <div>
                                        <p className="job-name">{job.title}</p>
                                        <p className="job-info">
                                            {job.department_name}{" "}|{" "}
                                            {country}
                                        </p>
                                        <a className="btn btn-main btn-sm">Apply Now</a>
                                    </div>
                                </a>
                            </div>
                        )
                    })}

                    {filteredJobs.length === 0 &&
                        <div className="feedback-message">
                            <p>There are no openings that match your current filters, please check again later.</p>
                            <p>Can't find the job you're looking for? <a href="mailto:info@magnitt.com" className="red">Contact us</a> and let us know what makes you a great fit for our team!</p>
                        </div>
                    }

                </div>

                {isCareersPage &&
                    <>
                        <hr />
                        <div className="job-secondary">
                            <h6>Looking for an internship in the VC Ecosystem?</h6>
                            <p>Intern with us and gain hands-on experience, mentorship, and the chance to contribute to innovative projects in a supportive and dynamic environment!</p>
                            <a className="btn btn-main" href="/internships">Explore internships</a>
                        </div>
                    </>
                }

            </div>
        )

    }

}

export default OpenPositions;