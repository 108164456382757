import React, { Component } from 'react'
import MicroFrontend from "../MicroFrontend";

class SideNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            refresh: (props.userState && props.userState.is_loggedin ? 1 : 0),
        }
    }

    // refresh component when login status changes
    componentDidUpdate(prevProps) {
        const { userState } = this.props;
        try {
            if (userState && userState.is_loggedin !== prevProps.userState.is_loggedin) {
                const refresh = (userState.is_loggedin ? 1 : 0)
                this.setState({ refresh });
            }
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const { refresh } = this.state;
        return (
            <MicroFrontend refresh={refresh} name="SideNav" />
        )
    }
}

export default (SideNav);