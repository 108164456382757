/**
 * MAGNiTT access roles and their respective ids
 */
const userRoles = {
    development: {
        directory_access: 8,
        sourcing_access: 20,
        enterprise_access: 11,
        contact_access: 40,
        advanced_analytics_access: 38,
        limited_directory_access: 46,
        custom_analytics_access: 47,
        custom_directory_access: 49
    },
    production: {
        directory_access: 8,
        sourcing_access: 20,
        enterprise_access: 11,
        contact_access: 40,
        advanced_analytics_access: 39,
        limited_directory_access: 52,
        custom_analytics_access: 54,
        custom_directory_access: 57
    }
}

export const MAGNITT_ACCESS_ROLES = userRoles[process.env.NODE_ENV];
