import axios from 'axios';

import { API_URL_NEW } from '/src/lib/endpoints'
import { ALLOWED_QUERY_OPERATOR, JOB_TYPES } from '/src/lib/constants';
import { prepareSelectParam, prepareFilterParam } from '/src/lib/queryParams'



const jobApi = {

    getJobsData: (jobType = "jobs", limit = 25, page = 1) => {

        const fields = prepareSelectParam(['id', 'title', 'code', 'department_id', 'location_data', 'department_data']);

        /*
         * this API is called on careers page and internships page
         * --> if careers page, include all job types except internships
         * --> if interns page, only include internships
         */
        const jobTypeOperator = (jobType === "internships" ? ALLOWED_QUERY_OPERATOR.equal : ALLOWED_QUERY_OPERATOR.notEq);

        const filters = prepareFilterParam([
            { key: 'status', value: [1], op: ALLOWED_QUERY_OPERATOR.equal }, 
            { key: 'entity_id', value: [617], op: ALLOWED_QUERY_OPERATOR.equal }, 
            { key: 'type_id', value: [JOB_TYPES.intern], op: jobTypeOperator }
        ]);

        var url = `${API_URL_NEW}/job?fields=${fields}&filters=${filters}&page=${page}&sort=published_at^:desc`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });

    }

}

export default jobApi;