import axios from 'axios';

import { API_URL_NEW } from '/src/lib/endpoints'
import { prepareSelectParam } from '/src/lib/queryParams'



const magnittDepartment = {

    getDepartments: () => {
        const fields = prepareSelectParam(['id', 'name'])
        var url = `${API_URL_NEW}/magnitt-department?fields=${fields}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    resolve({
                        records: response.data.data,
                        count: response.data.navigation.total
                    });
                } else {
                    resolve({
                        records: [],
                        count: 0
                    });
                }
            }).catch((err) => reject(err));
        });
    },

}

export default magnittDepartment;