import * as marketMapFields from '../components/MarketMapFormFields';
import * as reusableFields from '/src_temp/components/Single-SICE/reusable/CreateEditFields.js';
import { MapPreview } from '../components/MapPreview';



const r = { ...marketMapFields, ...reusableFields };

let tempFormFields = [
    {
        name: "Market Map Settings",
        content: [r.MapType, r.MarketMapName, r.IndustryFocusMult, r.RegionMultiSelect, r.CountryMultiSelect, r.MapGrouping, r.MapSorting],
        reqFields: [
            { name: 'mapType', msg: 'market map type' },
            { name: 'marketMapName', msg: 'market map name' },
            { name: 'mapGrouping', msg: 'map grouping' },
            { name: 'mapSorting', msg: 'map sorting' }
        ]
    },
    {
        name: 'Check Map Preview',
        content: [MapPreview]
    }
]

// add id + step count
let temp = tempFormFields;
for (let i in temp) {
    temp[i].id = i;
    temp[i].step = "Step " + (parseInt(i) + 1);

    // visible = page visibility (1st page visible by default)
    temp[i].visible = (i == 0) ? true : false;

    if (!temp[i].reqFields) {
        temp[i].reqFields = [];
    }

    tempFormFields = temp;
}

export const formFields = tempFormFields;