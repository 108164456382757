import axios from 'axios';
import { LOGGER_URL } from './endpoints';

const axiosInstance = axios.create();

// we use it to check service-to-service communication, not needed at the moment
export const logUserRequest = (req = {}, userToken = "", reqID = "") => {

    return;

    const payload = {
        req_id: reqID,
        type: "REQUEST",
        URL: req.url,
        user_token: userToken,
        status: (req && req.status),
        req: req,
        res: "",
        error: "",
        created_at: new Date(),
    };

    axiosInstance.post(`${LOGGER_URL}`, payload)
        .catch(err => {
            // console.log('err:', err);
        });
}

export const logResponse = (res = "", userToken = "", reqID = "") => {

    return;

    const payload = {
        req_id: reqID,
        type: "RESPONSE",
        URL: res && res.config && res.config.url,
        user_token: userToken,
        status: res && res.status,
        req: res && res.config,
        res: res && res.data,
        error: "",
        created_at: new Date(),
    };

    axiosInstance.post(`${LOGGER_URL}`, payload)
        .catch(err => {
            // console.log('err:', err);
        });
}

export const logError = (err = "", userToken = "", reqID = "") => {

    return;

    const payload = {
        req_id: reqID,
        type: "ERROR",
        URL: err && err.config && err.config.url,
        user_token: userToken,
        status: err && err.response && err.response.status,
        req: err && err.config,
        res: err && err.response.data,
        error: err && err.response.data.errors,
        created_at: new Date(),
    };

    axiosInstance.post(`${LOGGER_URL}`, payload)
        .catch(err => {
            // console.log('err:', err);
        });
}

export const logGeneralError = (err = "", pageUrl = "") => {

    return;
    
    const payload = {
        type: "ERROR",
        page: pageUrl,
        error: err,
        created_at: new Date(),
    }

    axiosInstance.post(`${LOGGER_URL}`, payload)
        .catch(err => {
            // console.log('err:', err);
        });
}


