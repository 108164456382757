import { sharedConstants } from 'shared-helpers';

// from shared-helpers
export const LOG_TYPES = sharedConstants.LOG_TYPES;
export const ALLOWED_QUERY_OPERATOR = sharedConstants.ALLOWED_QUERY_OPERATOR;
export const TAXONOMIES = sharedConstants.TAXONOMIES;
export const COOKIE_NAME = sharedConstants.COOKIE_NAME;
export const USER_STATUS = sharedConstants.USER_STATUS;
export const REPORT_TYPES = sharedConstants.REPORT_TYPES;
export const TELEPHONE_REGEX = sharedConstants.TELEPHONE_REGEX;

// regex
export const PLAY_STORE_LINK_REGEX = /(https:\/\/)?(play\.google\.com\/)[\w\d?@%&$+~=*#/._-]+/;
export const APP_STORE_LINK_REGEX = /(https:\/\/)?(apps\.apple\.com\/)[\w\d?@%&$+~=*#/._-]+/;
export const ITUNES_STORE_LINK_REGEX = /(https:\/\/)?(itunes\.apple\.com\/)[\w\d?@%&$+~=*#/._-]+/;

export const USER_ROLES = ['Entrepreneur', 'VC Fund', 'Angel Investor', 'Incubator/ Accelerator', 'Mentor/ Advisor', 'Service Provider']

export const JOB_STATUS = {
    draft: 0,
    published: 1,
    unPublished: 2
}

export const ENTITY_STATUS = {
    default: 0,
    claimedOrAdded: 1, // Claimed or added by MAGNiTT team
    invited: 2 // Invited by a user
}

export const FIELD_SEPARATOR = "^,"
export const EQUAL_SYMBOL = "^:"
export const VALUE_SEPARATOR = "^;"
export const DATE_FORMAT = 'DD/MM/YYYY'
// export const DEFAULT_TEAM_ROLE = 6578;

// It's team role like founder, CTO etc.
export const DEFAULT_TEAM_ROLE = 9;

// It's an access role which indicates that what user can do
export const DEFAULT_ROLE_ID = 4;
export const TEAM_EDIT_ROLE_ID = 17;

export const MAX_LIMIT = 300;

// todo: this should be called submission status
export const APP_STATUS = {
    pending: 1,
    accepted: 2,
    declined: 3
};

export const ACCESS_ROLES = {
    directory: 8
}

export const JOB_APP_STATUS = {
    pending: 1,
    accepted: 2,
    declined: 3
};

export const CURRENCIES = [
    { id: 'USD', name: 'USD' },
    { id: 'AED', name: 'AED' },
    { id: 'LE', name: 'LE' },
    { id: 'SAR', name: 'SAR' },
    { id: 'BD', name: 'BD' },
    { id: 'DZD', name: 'DZD' },
    { id: 'IQD', name: 'IQD' },
    { id: 'JOD', name: 'JOD' },
    { id: 'KD', name: 'KD' },
    { id: 'LL', name: 'LL' },
    { id: 'LYD', name: 'LYD' },
    { id: 'MAD', name: 'MAD' },
    { id: 'OMR', name: 'OMR' },
    { id: 'QR', name: 'QR' },
    { id: 'SYP', name: 'SYP' },
    { id: 'TND', name: 'TND' },
    { id: 'YER', name: 'YER' },
]

export const PERIODS = [
    { id: 'YEAR', name: 'Per Year' },
    { id: 'MONTH', name: 'Per Month' },
    { id: 'WEEK', name: 'Per Week' },
    { id: 'DAY', name: 'Per Day' },
    { id: 'HOUR', name: 'Per Hour' },
]

export const ORDER_TYPES = {
    SUBSCRIPTION: 1,
    REPORT: 2,
    SAVE_CARD: 3,
    DATA_EXPORT: 4
}

export const SAVED_SEARCH_KEY = 'searchFilters'
export const FILTER_LAYOUT_TYPES = {
    ecosystem: 'ecosystem',
    startups: 'startups',
    fundingRounds: 'fundingRounds',
    exits: 'exits',
    jobs: 'jobs',
    funds: 'funds',
    people: 'people',
    privateEquity: 'privateEquity'
}


export const ENTITIES = {
    startup: 'startup'
}

export const JOIN_FUNDING_ROUND_STATUS = {
    accepted: 1,
    pending: 2,
    rejected: 3,
}

export const DEF_MAGNITT_DESCRIPTION = "MAGNiTT is the largest data platform for investors, founders and corporates in the Emerging Venture Markets (EVM). Discover funding information on your competitors, insights on startup trends, and download research reports online.";
export const DEF_MAGNITT_DESCRIPTION_PRICING = "MAGNiTT has a subscription offering that provides access to data, research and analytics on the Middle East, Africa, Turkey and Pakistan venture funding ecosystem. Access to reports, interactive dashboards and exports to CSV.";

export const MENA_COUNTRIES_IDS = [5664, 5674, 5713, 5740, 5746, 5751, 5755, 5778, 5791, 5794, 5802, 5812, 5824, 5827, 5832, 5841, 5847, 5856]
export const PAKISTAN_COUNTRY_ID = 5792
export const TURKEY_COUNTRY_ID = 5842
export const UAE_COUNTRY_ID = 5847;

export const TOP_INDUSTRIES_IDS = [15, 31, 1, 45, 16, 26, 34, 21, 48, 36, 10, 22, 47, 19, 13, 14, 37, 28, 38, 7]

export const EXCEPTION_INDUSTRIES_SLASH = ['ar-vr', 'professional-corporate-services']
export const EXCEPTION_INDUSTRIES_AND = ['travel-and-tourism', 'network-and-community', 'hr-and-recruitment', 'food-and-beverage', 'fashion-and-beauty', 'delivery-and-logistics']

export const EXCEPTION_INVESTOR_TYPES = {
    'crowd-funder': 'crowd-funder',
    'venture-capital-vc': 'venture capital (vc)',
    'corporate-vc-cvc': 'corporate vc (cvc)',
    'co-working-space': 'co-working space'
}

export const FUNDING_STATUS = {
    pending: 0,
    verified: 1,
    unverified: 2,
    draft: 3
}

export const OPTIONS_TAXO_OBJ = {
    LOCATIONS_CITY: {
        name: "location_cities"
    },
    LOCATIONS_COUNTRY: {
        name: "location_countries",
    },
    DEV_STAGES: {
        name: "taxo_dev_stages",
    },
    INDUSTRIES: {
        name: "industries_all"
    },
    TEAM_ROLES: {
        name: "team_roles_all"
    }
};

const fundingStages = {
    development: {
        bridge: 6697,
        acquisition: 6699,
        undisclosed: 6686,
        venture_undisclosed: 6823,
        private_equity: 6913
    },
    production: {
        bridge: 6697,
        acquisition: 6699,
        undisclosed: 6787,
        venture_undisclosed: 6686,
        private_equity: 6823
    }
}

const jobTypes = {
    development: {
        intern: 6731
    },
    production: {
        intern: 6741
    }
}

export const JOB_TYPES = jobTypes[process.env.NODE_ENV];
export const FUNDING_STAGES = fundingStages[process.env.NODE_ENV];

const northstar_entity_id = {
    development: 51948,
    production: 52037
}

export const NORTHSTAR_ENTITY_ID = northstar_entity_id[process.env.NODE_ENV]

export const FUNDING_STAGES_LIST = [
    { name: 'No fundraising', value: 'No fundraising' },
    { name: 'Self Funded', value: 'Self Funded' },
    { name: 'Friends and Family', value: 'Friends and Family' },
    { name: 'SEED', value: 'SEED' },
    { name: 'Series A', value: 'Series A' },
    { name: 'Series B', value: 'Series B' },
    { name: 'Angel Investment', value: 'Angel Investment' },
    { name: 'Pre-SEED', value: 'Pre-SEED' },
    { name: 'Series C', value: 'Series C' },
    { name: 'Series D', value: 'Series D' },
    { name: 'Bridge', value: 'Bridge' },
    { name: 'Convertible Note', value: 'Convertible Note' },
    { name: 'Acquisition', value: 'Acquisition' }
]

export const DIRECTORY_STARTUPS = [
    {
        name: 'Startups in UAE',
        link: '/en-ae/startups'
    },
    {
        name: 'Startups in KSA',
        link: '/en-sa/startups/'
    },
    {
        name: 'Startups in Egypt',
        link: '/en-eg/startups/'
    },
    {
        name: 'Startups in Pakistan',
        link: '/en-pk/startups/'
    },
    {
        name: 'Startups in Turkey',
        link: '/en-tr/startups/'
    }
];

export const DIRECTORY_INVESTORS = [
    {
        name: 'Venture Capital (VC) in United Arab Emirates (UAE)',
        link: '/en-ae/investors/venture-capital-vc'
    },
    {
        name: 'Angel Group in Saudi Arabia (KSA)',
        link: '/en-sa/investors/angel-group'
    },
    {
        name: 'Accelerator in Egypt',
        link: '/en-eg/investors/accelerator'
    },
];

// data exports
export const DATA_SET_TYPES = {
    startups: 'STARTUP',
    ecosystem: 'ECOSYSTEM',
    funding: 'FUNDING',
    funds: 'FUND',
    people: 'PEOPLE',
    privateEquity: 'PRIVATE EQUITY'
}

// contact forms type
export const CONTACT_FORM_TYPE = {
    marketing: 1,
    enterprise: 2,
    demo_request: 3,
    bespoke_report: 4
}

// contact forms popup source
export const CONTACT_FORM_POPUP_SOURCE = {
    pricing: 'pricing',
    enterprise: 'enterprise',
    contact: 'contact',
    news: 'news',
    profiles: 'profiles',
    report: 'report',
    directory: 'directory',
    profile_contact: 'profile_contact',
    directory_contact: 'directory_contact',
    custom_market_maps: 'custom_market_maps'
}

// mapping of form source in hubspot
export const HUBSPOT_FORM_SOURCE = {
    homepage_demo: 'demo_homepage',
    about_us: 'demo_about_us',
    dir_locked_contacts: 'sales_directory_contact',
    single_profile_locked_contacts: 'sales_profile_contact',
    single_profile_locked_sections: 'sales_profile_preview',
    dir_locked_startups_landscape_view: 'sales_directory_startup',
    demo_dir_widget: 'demo_directory',
    startups_dir_locked_section: 'sales_directory_startup',
    investor_dir_locked_section: 'sales_directory_investor',
    exits_dir_locked_section: 'sales_directory_exits',
    funds_dir_locked_section: 'sales_directory_funds',
    people_dir_locked_section: 'sales_directory_people',
    private_equity_dir_locked_section: 'sales_directory_private_equity',
    research_purchase_btn: 'sales_report_purchase',
    research_widgets: 'sales_report_content',
    analytics: 'sales_analytics',
    marketing_widgets: 'sales_news',
    marketing: 'sales_advertisewithus',
    custom_market_map: 'sales_marketmap',

    // mapping for pricing page
    pricing_contact_sales: 'sales_pricing',
    pricing_request_demo: 'demo_pricing',
    pricing_bespoke_report: 'sales_pricing_report',
    pricing_free_demo_trial: 'demo_pricing_faq',
    sales_pricing_popup: 'sales_pricing_popup',

    enterprise_form: 'sales_enterprise',
    bespoke_report: 'sales_enterprise_report',
    demo_request: 'demo_enterprise',
    contact_sales: 'support_contactus',
    faq: 'demo_faq',
    download_success: 'demo_report_download',
    directory_presentation: 'sales_directory_presentation'
}

// Single ICE - donut chart insufficient data messages
export const INSUFFICIENT_DATA = "Portfolio does not have sufficient data for this chart";

// Personal Dashboard - recent funding insufficient data message
export const DASHBOARD_INSUFFICIENT_DATA = "There are no recorded fundings for the last 4 weeks";

// directory pagination
export const RECORD_COUNT_OPTIONS = [10, 25, 50, 100];
export const RECORD_COUNT_DEFAULT = {
    default: RECORD_COUNT_OPTIONS[0],
    loggedOut: 5
}

// Valuation for Directory and Single SICE page
export const VALUATION_INFO_TEXT = {
    actual: "This range represents the actual valuation at the time of funding",
    estimate: "This range represents an estimate for the valuation at the time of funding, using many data points including stage, geography and industry",
    owner: "This range can be adjusted by editing the information in this Funding round to include the actual post-money valuation. This will always be presented as a range"
}

export const PENDING_INFO_TEXT = {
    not_owner: "This funding information has not been verified by a member of our team",
    owner: "This funding information has not been verified by a member of our team, to get your data verified please contact us"
}

export const SAVED_TEMPLATE_TYPES = {
    marketMapStartup: 'startup_market_map',
    marketMapInvestor: 'investor_market_map'
}

export const HELPDESK_LINKS = {
    directories: "https://helpdesk.magnitt.com/magnitt-directories",
    analytics: "https://helpdesk.magnitt.com/what-is-advance-analytics"
}

// Single Startup Benchmarking Tool
export const STARTUP_BENCHMARKING_HEADERS = [
    { label: 'Company Profile', key: 'company-profile' },
    { label: 'Latest Funding', key: 'latest-funding' },
    { label: 'Investor(s)', key: 'investors-data' },
    { label: 'Exit', key: 'exit-data' },
    { label: 'Team Size and Org', key: 'team-data' },
    { label: 'Founders', key: 'founders-data' }
]

export const NO_SIMILAR_STARTUPS = "Similar startups are not available for this profile for comparison"

// Chart colors
export const STACKED_BAR_LINE_COLOR = ['#D31148', '#2C373B', '#FF8811'];
export const HORIZONTAL_STACKED_BAR_COLOR = ['#D31148', '#2C373B'];

export const DEAL_TERM_MEANING = `This is the specific conditions and rules agreed upon by investors and startup founders when making an investment. These terms lay out the structure of the agreement between the parties.`;
export const INVESTMENT_VEHICLE_MEANING = `This is the specific mechanisms or financial instruments through which investors provide capital to startups. These vehicles allow investors to participate in the growth and success of the company.`;

export const ENTITY_TYPE = {
    startup: 'STARTUP',
    investor: 'INVESTOR',
    enabler: 'ENABLER',
    corporate: 'CORPORATE',
    fund: 'FUND',
    people: 'PEOPLE',
}

export const COMPONENT_NAME = {
    Startup: 'Startup',
    CreateDashBoard: 'CreateDashBoard'
}

export const DIRECTORY = {
    startups: 'startups',
}

export const API_ERROR_MESSAGES = {
    nameAlreadyExist: 'name already exists',
    noChangesUpdated: 'No changes updated',
}

export const FUNDING_VERIFICATION_STATUS = {
    pending: 0,
    verified: 1,
    unverified: 2,
    draft: 3
};

export const HOME_DASHBOARD_SECTION_MESSAGES = {
    map: 'Uncover trends and insights in the world of startups and venture funding, all aligned with your custom filters',
    view: 'Save your filters and search in any directory to easily track investments, startups and trends based on your interests'
}

// MISK specific employee count chart
export const MISK_CLIENT_DIRECTORY_ID = 5;