import React from 'react'
import MicroFrontend from "../MicroFrontend"

const MagnittLoader = ({data}) => {
    return <MicroFrontend
        data={data}
        name="MagnittLoader"
    />
}

export default (MagnittLoader);