import React, { Component } from 'react'
import MicroFrontend from "../../MicroFrontend"

class HorizontalStackedBarChart1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            refresh: 0,
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.data !== prevProps.data) {
            const refresh = this.state.refresh + 1;
            this.setState({ refresh });
        }
    }

    render() {
        return <MicroFrontend
            data={this.props.data}
            refresh={this.state.refresh}
            name="HorizontalStackedBarChart1"
        />
    }
}

export default (HorizontalStackedBarChart1);
