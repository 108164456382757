import axios from 'axios';
import { API_URL_NEW } from '/src/lib/endpoints';
import { prepareFilterParam, prepareSelectParam } from '/src/lib/queryParams';
import { prepareAPIFields } from '../pages/customMarketMaps/helpers/MarketMapHelpers';



export const customMarketMapApi = {

    getByUser: (user_id, page, limit, filterObj) => {

        const filters = prepareFilterParam(filterObj);
        const fields  = prepareSelectParam(['user_id', 'name', 'entity_type', 'id']);
        const sort = 'updated_at^:desc';

        // When user deletes a map, navigates to another page and then comes back again
        // The API call made gives a cached response including the deleted map
        // Attaching timestamp to URL to prevent browser cache
        const timestamp = new Date().getTime();
        const url = `${API_URL_NEW}/user/${user_id}/saved-template?fields=${fields}&filters=${filters}&limit=${limit}&page=${page}&sort=${sort}&timestamp=${timestamp}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then(async (res) => {
                if (res && res.data) {
                    resolve(res.data);
                } else {
                    reject()
                }
            }).catch(err => reject(err))
        })

    },

    getMapByID: (map_id, user_id) => {
        const fields = prepareSelectParam(['user_id', 'name', 'entity_type', 'id', 'filters', 'options', 'group_by']);
        const url = `${API_URL_NEW}/user/${user_id}/saved-template/${map_id}?fields=${fields}`;
        return new Promise((resolve, reject) => {
            axios.get(url).then(async (res) => {
                if (res && res.data && res.data.data) {
                    resolve(res.data.data);
                } else {
                    reject()
                }
            }).catch(err => reject(err))
        })
    },

    handleDeleteMap: (map_id, user_id) => {
        const url = `${API_URL_NEW}/user/${user_id}/saved-template/${map_id}`;
        return new Promise((resolve, reject) => {
            axios.delete(url).then(async (res) => {
                resolve()
            }).catch(err => reject(err))
        })
    },

    createCustomMap: (data) => {
        const url = `${API_URL_NEW}/user/${data.userId}/saved-template`;
        const payload = prepareAPIFields(data);
        return new Promise((resolve, reject) => {
            axios.post(url, payload).then(async (res) => {
                resolve(res);
            }).catch(err => {
                resolve(err);
            })
        })
    },

    editCustomMap: (data) => {
        const url = `${API_URL_NEW}/user/${data.userId}/saved-template/${data.id}`;
        const payload = prepareAPIFields(data);
        return new Promise((resolve, reject) => {
            axios.patch(url, payload).then(async (res) => {
                resolve(res);
            }).catch(err => {
                resolve(err);
            })
        })
    },

}