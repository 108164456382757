import React from 'react';
import { Helmet } from "react-helmet";

// reusable
import ValuesSection from './components/ValuesSection';
import OpenPositions from './components/OpenPositions';
import { OurBenefits } from './components/OurBenefits';
import { EmployeeReviews } from './components/EmployeeReviews';
import { OurMemories } from './components/OurMemories';

// constant
import { WEB_URL, PUBLIC_IMG_URL } from '/src/lib/endpoints';
import { DEF_MAGNITT_DESCRIPTION } from '/src/lib/constants';



export const Jobs = () => {
    return (
        <div className="careers-page footer-margin-offset">

            <Helmet
                title={'Careers | MAGNiTT'}
                link={[
                    {
                        rel: 'canonical',
                        href: `${WEB_URL}/jobs`
                    }
                ]}
                meta={[
                    {
                        name: 'description',
                        content: DEF_MAGNITT_DESCRIPTION
                    },
                    {
                        name: 'abstract',
                        content: DEF_MAGNITT_DESCRIPTION
                    },
                    {
                        property: 'og:type',
                        content: 'article'
                    },
                    {
                        property: 'og:url',
                        content: `${WEB_URL}/jobs`
                    },
                    {
                        property: 'og:title',
                        content: 'MAGNiTT Careers'
                    },
                    {
                        property: 'og:image',
                        content: `${PUBLIC_IMG_URL}/careers/careers-share-image.jpg`
                    },
                    {
                        property: 'og:image:secure_url',
                        content: `${PUBLIC_IMG_URL}/careers/careers-share-image.jpg`
                    },
                ]}
                script={[
                    {
                        'type': 'application/ld+json',
                        'innerHTML': `{
                        "@context": "http://schema.org",
                        "@type": "BreadcrumbList",
                        "itemListElement": [{
                            "@type": "ListItem",
                            "position": 1,
                            "name": "jobs",
                            "item": "${WEB_URL}/jobs"
                        }]
                    }`
                    }
                ]}
            />

            <div className="careers-hero-section">
                <div className="video-box">

                    {/* <video autoPlay muted loop playsInline>
                        <source src="https://joy1.videvo.net/videvo_files/video/free/video0470/large_watermarked/_import_617a499d6604a0.84612003_preview.mp4" type="video/mp4" />
                        fallback image
                        <img src="" title="Your browser does not support the <video> tag" />
                    </video> */}

                    <div className="video-overlay">
                        <div>
                            <h1>We're here to create something epic! Are you up for the challenge?</h1>
                            <a className="btn btn-lg btn-main" href="#open-positions">
                                See open positions
                                <i className="fas fa-search"></i>
                            </a>
                        </div>
                    </div>

                </div>
            </div>

            <div className="careers-mission-section">
                <div className="row">
                    <div className="col-lg-6 col-xl-7">
                        <div className="mission-img">
                            <video autoPlay muted loop playsInline>
                                <source src={`${PUBLIC_IMG_URL}/careers/team-video.mp4`} type="video/mp4" />
                                {/* fallback img is displayed in the split second before the video loads */}
                                <img src={`${PUBLIC_IMG_URL}/careers/team-video-fallback-img.jpg`} title="Your browser does not support the <video> tag"></img>
                            </video> 
                        </div>
                    </div>
                    <div className="col-lg-6 col-xl-5">
                        <div className="mission-text">
                            <h6 className="careers-heading">
                                Our Mission
                            </h6>
                            <p>To become the most powerful startup and venture data platform of all emerging markets.</p>
                            {/* {" "} spacing is needed for mobile */}
                            <p>{" "}How?</p>
                            <p>{" "}By empowering our people and creating an environment where they can grow!</p>
                            <a className="btn btn-main" href="/aboutus">
                                Read more
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <ValuesSection />

            <div className="careers-global-section">
                <div className="careers-heading text-center">
                    Global Unity
                </div>
                <div className="row">
                    <div className="col-xl-3 order-xl-1 order-2">
                        <div className="counts-flex">

                            <div className="global-counts-box">
                                <h6 className="text-muted">
                                    Nationality Diversity
                                </h6>
                                <div className="text-global align-items-center">
                                    <div>
                                        <i className="fas fa-globe-africa mr-3"></i>
                                    </div>
                                    <div>
                                        <p><span>15</span> Countries</p>
                                        <p><span>04</span> Continents</p>
                                    </div>
                                </div>
                            </div>

                            <div className="global-counts-box">
                                <h6 className="text-muted">
                                    Gender Diversity
                                </h6>
                                <div className="text-global">
                                    <div className="counts-section">
                                        <img src={`${PUBLIC_IMG_URL}/icons/person-female.svg`} className="svg-invert" />
                                        <p className="icon-label text-muted">Female</p>
                                        <span>52%</span>
                                        <span>60%</span>
                                    </div>
                                    <div className="counts-section">
                                        <img src={`${PUBLIC_IMG_URL}/icons/person-male.svg`} className="svg-invert" />
                                        <p className="icon-label text-muted">Male</p>
                                        <span>48%</span>
                                        <span>40%</span>
                                    </div>
                                    <div className="counts-section">
                                        <p>Employees</p>
                                        <p>Management</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="col-xl-9 order-xl-2 order-1">
                        <img src={`${PUBLIC_IMG_URL}/landing-pages/employee_map.svg`} className="img-fluid" id="map-graphic" />
                    </div>
                </div>
            </div>

            <OurBenefits />
            <EmployeeReviews />
            <OpenPositions jobType="jobs" />
            <OurMemories />

        </div>
    )
}