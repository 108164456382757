import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';

// constants
import { DEF_MAGNITT_DESCRIPTION, ALLOWED_QUERY_OPERATOR, SAVED_TEMPLATE_TYPES } from '/src/lib/constants';
import { MAGNITT_ACCESS_ROLES } from '/src/lib/constants/userRoles';

// helpers
import * as CustomMarketMapHelpers from './helpers/MarketMapHelpers';
import * as filterHelper from '/src/lib/filterHelper';
import SegmentHelperClass from '/src/lib/segmentHelper';

// reusable
import { MapPreview } from './components/MapPreview';
import MagnittLoader from '/src/micro-frontends/MagnittLoader';
import Feedback from '/src/components/reusable/Feedback';
import SelectedMapOptions from './helpers/SelectedMapOptions';



const SegmentHelper = new SegmentHelperClass();

class ViewCustomMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            loadingError: false,
            filters: [],
            mapGrouping: [],
            mapSortIndex: {},
            mapSorting: [],
        };

        // get id from url
        const { match } = this.props;
        this.mapId = match.params.id;
    }

    componentDidMount = () => {
        this.checkUserAccess();
        SegmentHelper.trackEvent('Custom Market Map - Viewed');
    }

    checkUserAccess = () => {
        const { user } = this.props;
        const hasAccess = (user.is_loggedin && user.roles.includes(MAGNITT_ACCESS_ROLES.directory_access));

        if (hasAccess) {
            this.getMapData();
        } else {
            this.handleNoAccessRedirect();
        }
    }

    getMapData = async () => {
        const { user } = this.props;
        try {
            await CustomMarketMapHelpers.getMapData(this.mapId, this.decodeSavedFilters, user.user_id).then(
                res => {
                    // verify that user owns this map
                    if (res.mapOwner === user.user_id) {
                        this.mapToState(res);
                        this.setState({
                            isLoading: false,
                            loadingError: false
                        });
                    } else {
                        this.handleNoAccessRedirect();
                    }
                }
            )
        } catch (error) {
            if (error) {
                this.setState({
                    loadingError: true,
                    errorMessage: `Error loading market map. Please try again or contact our team if the error persists. (Error code: ${error.message})`
                })
            } else {
                window.location.href = "/market-maps"
            }
        }
    }

    handleNoAccessRedirect = () => {
        window.location.href = '/market-maps';
    }

    decodeSavedFilters = (filters) => {
        const decode = filterHelper.decodeFilters(filters);
        let filterArray = [];
        decode.map(filterItem => {
            const key = Object.getOwnPropertyNames(filterItem)[0];
            filterArray.push(
                {
                    key: key,
                    value: filterItem[key],
                    op: ALLOWED_QUERY_OPERATOR.equal
                }
            )
        })
        this.setState({ filters: filterArray });
    }

    mapToState = (stateObj) => {
        Object.entries(stateObj).forEach(([key, value]) => {
            this.setState({ [key]: value });
        });
    }

    render() {

        const { user } = this.props;
        const { isLoading, marketMapName, mapType, loadingError, errorMessage } = this.state;

        if (loadingError) {
            return (
                <div className="min-height-box">
                    <Feedback message={errorMessage} />
                </div>
            )
        }

        return (
            <div className="directory content-box footer-margin-offset">

                <Helmet
                    title={`Custom Market Map | MAGNiTT`}
                    meta={[
                        { name: 'description', content: DEF_MAGNITT_DESCRIPTION },
                        { name: 'abstract', content: DEF_MAGNITT_DESCRIPTION },
                        { property: 'rating', content: 'general' },
                        { property: 'og:type', content: 'article' },
                        { property: 'og:title', content: 'Custom Market Map' },
                    ]}
                />

                {isLoading ?
                    <MagnittLoader data={{ height: "300px", background: "transparent" }} />
                    :
                    <div className="create-edit-form">
                        <div className="mid-width-info-box">
                            <div className="info-box-container">
                                <div className="db-header">
                                    <div className='market-map-header'>
                                        <div>Market Map: <span className="text-muted">{marketMapName}</span></div>
                                        <div>
                                            <a className="btn btn-sm btn-main" href={`/market-maps/${this.mapId}/edit`}>
                                                <i className="fas fa-edit"></i>
                                                Edit Map
                                            </a>
                                            <a className="btn btn-sm btn-main-outline" href="/market-maps">
                                                <i className="fas fa-globe-americas"></i>
                                                View All Maps
                                            </a>
                                        </div>
                                    </div>
                                    <SelectedMapOptions mapFilters={this.state.filters} mapSorting={this.state.mapSortIndex} mapGrouping={this.state.mapGrouping} mapType={mapType} />
                                </div>

                                {/* <h6>Selected Filters:</h6> */}
                                <div className="info-box-body preview-box">
                                    <div className="form-box-layout">
                                        <MapPreview view='finalMap' user={user} state={this.state} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )

    }

}

const mapStateToProps = (state) => ({
    user: state.userReducer
})

export default connect(mapStateToProps)(withRouter(ViewCustomMap));
