import React from 'react';

export const VerifiedFundingNote = (props) => {

    const { entityType } = props;
    let label = '';
    if (entityType === 'investor') {
        label = 'investments';
    } else if (entityType === 'startup') {
        label = 'startups with funding rounds';
    }

    return (
        <div className='network-map-note'>
            <i className="fas fa-info-circle"></i>{" "}
            <span className='font-weight-bold'>Note:</span> Only includes {label} verified by MAGNiTT
        </div>
    )
}