import axios from 'axios';

import isEmpty from 'lodash/isEmpty';
import { API_URL_NEW } from './endpoints';
import { PERIODS } from './constants';
import { ERROR_MESSAGES } from './errorMessages';
import { sharedHelpers } from 'shared-helpers';

// from shared-helpers
export const getItemWebUrl = sharedHelpers.getItemWebUrl;
export const deserializeData = sharedHelpers.deserializeData;
export const checkEmailStatus = sharedHelpers.checkEmailStatus;
export const isValidEmail = sharedHelpers.isValidEmail;
export const amountFormatter = sharedHelpers.formatValue;
export const formatDateCharts = sharedHelpers.formatDate;
export const formatCurrency = sharedHelpers.formatCurrency;

export const nl2br = (str) => {
    return (str + '').replace(/\n/g, "<br />");
}

export const websiteValidate = (input) => {

    // Remove spaces from the beginning and ending of the string
    input = input.trim();
    if (input.indexOf("http://") === 0 || input.indexOf("https://") === 0) {
        return true;
    }
    return false;
}

export const socialUrlValidate = (input, platform) => {
    if (input.indexOf(`http://www.${platform}.com/`) === 0 || input.indexOf(`https://www.${platform}.com/`) === 0) {
        return true;
    }
    return false;
}

/**
 * @param {any string with spaces or without spaces} str
 * @returns {string in which spaces would be replaced with dashes(-)}
 */
export const replaceSpacesWithDashes = (str) => {
    if (str) {
        str = str.replace(/\s+/g, '-').toLowerCase();
        return str;
    }
    return "";
}

/**
 * @param {String} string
 * @param {Number} length
 * @returns {String}
 */
export const limitStringChars = (string, length) => {
    const trimmedString = string.length > length ?
        string.substring(0, length - 3) + "..." :
        string;
    return trimmedString;
}

/**
 * @param {moment Object} date1 The bigger date or most recent one
 * @param {moment Object} date2 The smaller date or least recent one
 * @returns {Object} It will return an object containing the difference in years, months, days, hours, minutes and seconds
 */
export function diffYMDHMS(date1, date2) {
    let years = date1.diff(date2, 'year');
    date2.add(years, 'years');

    let months = date1.diff(date2, 'months');
    date2.add(months, 'months');

    let days = date1.diff(date2, 'days');
    date2.add(days, 'days');

    let hours = date1.diff(date2, 'hours');
    date2.add(hours, 'hours');

    let minutes = date1.diff(date2, 'minutes');
    date2.add(minutes, 'minutes');

    let seconds = date1.diff(date2, 'seconds');

    return { years, months, days, hours, minutes, seconds };
}

export function timeDiff(created) {
    let now = new Date().getTime()
    let created_date = new Date(created).getTime()
    let difference = (now - created_date) / 1000
    let time = ''

    if (difference > 3600 * 24 * 30 * 12) {
        time = Math.floor(difference / (3600 * 24 * 30 * 12))
        time += (time === 1) ? ' year ' : ' years '
    }

    else if (difference > 3600 * 24 * 30) {
        time = Math.floor(difference / (60 * 60 * 24 * 30))
        time += (time === 1) ? ' month ' : ' months '
    }

    else if (difference > 3600 * 24 * 7) {
        time = Math.floor(difference / (60 * 60 * 24 * 7))
        return time += (time === 1) ? ' week ' : ' weeks '
    }

    else if (difference > 3600 * 24) {
        time = Math.floor(difference / (60 * 60 * 24))
        return time += (time === 1) ? ' day ' : ' days '
    }

    else if (difference > 3600) {
        time = Math.round(difference / (60 * 60))
        return time += (time === 1) ? ' hour ' : ' hours '
    }
    else if (difference > 60) {
        time = Math.round(difference / (60))
        return time += (time === 1) ? ' minute ' : ' minutes '
    }
    else {
        return time += (time === 1) ? ' second ' : ' seconds '
    }

    return time
}

/**
 * @param {moment Object} date
 * @returns {string} return date in format MMM YYYY (Dec 2021)
 */
export function formatDate(date) {
    let content = 'N/A';
    if (date) {
        const dateObj = new Date();
        const options = {
            year: 'numeric',
            month: 'short',
        };
        dateObj.setFullYear(date.substr(0, 4));
        dateObj.setMonth(parseInt(date.substr(5, 2), 10) - 1);
        content = dateObj.toLocaleString("en-US", options);
    }
    return content;
}

/**
 * @param   {object} object to be sorted
 * @param   {string} parameter to be sorted by
 *
 * @returns {object} sorted by specific parameter
 */
export function sortObject(obj, parameter) {
    let sortedObj = obj.sort((a, b) =>
        a[parameter] < b[parameter] ? 1 : -1
    );
    return sortedObj;
}

export const extractIdFromPath = (path) => {
    if (path.charAt(0) === '/') path = path.substr(1)
    if (path.charAt(path.length - 1) === '/') path = path.substr(0, path.length - 1)
    let id = path.split('/')[1]
    return id
}

export const extractCodeFromPath = (path) => {
    if (path.charAt(0) === '/') path = path.substr(1)
    if (path.charAt(path.length - 1) === '/') path = path.substr(0, path.length - 1)
    let id = path.split('/')[2]
    return id
}

// Load redux state from the store
// If browser doens't have permissions, return undefined
export const loadState = () => {
    try {
        let serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        serializedState = JSON.parse(serializedState);

        return { userReducer: serializedState };
    } catch (err) {
        return undefined;
    }
}

// Save redux state in localstorage
export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (err) {
        console.log("Can't write to local storage");
    }
}

/**
 * This method will check either the string is URL or not
 * @refrence -> https://stackoverflow.com/a/5717133/6696353
 */
export const isURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

/**
 * verify whether a given string (e.g. a description) contains URLs or links
 */

export const stringContainsURLs = (str) => {
    // I used this method as I couldn't find a reliable regex check
    const url_partials = ['http', 'https://', '.com', 'www.', '<a', 'herf=', 'href=', '.net', '</a>'];
    let isInvalid = false;
    url_partials.some(element => {
        if (str.includes(element)) {
            isInvalid = true;
        }
    });
    return isInvalid;
}

/**
 * Make changes in entity type appropriately to use it in URL, e.g. USER -> people, STARTUP -> startups
 * @param {String} type entity type
 */
export const prepEntityTypeLower = (type) => {
    let strReturn = "";

    if (type) {
        type = type.toLowerCase();

        if (type === "user") {
            strReturn = 'people';

        } else {
            strReturn = type + 's';
        }
    }

    return strReturn;
}

/**
 * @param {Number} num A number on which perentage has to be applied
 * @param {Number} per Percent number
 */
export const calcPercentage = (num, per) => {
    return (num / 100) * per;
}

/**
 * Convert bytes into kbs, mbs and gbs
 * @param {Number} bytes
 * @param {Number} decimals
 * @returns {String}
 * @referecne  https://stackoverflow.com/a/18650828/6696353
 */
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * @param {Object}      error   Error object from server
 * @returns {String}            Extracted error message
 */
export const extractErrorMsg = (error = {}, defaultError = ERROR_MESSAGES.GENERAL_SHORT_ERROR_MSG) => {
    let retString = "";

    if (error && error.data && !isEmpty(error.data.errors) && error.data.errors.length > 0) {
        error.data.errors[0].message || error.message || defaultError;
    }

    return retString;
}

// get locations list
export const getLocations = () => {
    return new Promise((resolve, reject) => {
        let url = `${API_URL_NEW}/location/helper/country`;
        axios.get(url).then(async (response) => {
            if (response.data.status === 200) {
                resolve(response.data.data);
            } else {
                let error = new Error(response);
                throw error;
            }
        }).catch(error => {
            console.log("ERROR: ", error);
            reject(error);
        })
    })
}

// get industries list
export const getIndustries = () => {
    return new Promise((resolve, reject) => {
        let url = `${API_URL_NEW}/industry/helper`;
        axios.get(url).then(async (response) => {
            if (response.data.status === 200) {
                resolve(response.data.data);
            } else {
                let error = new Error(response);
                throw error;
            }
        }).catch(error => {
            console.log("ERROR: ", error);
            reject(error);
        })
    })
}

/**
 * method to create SEO URLs: /en-:iso/path/:industry
 * e.g /en-ae/startups/fintech
 */
export const getSEOUrl = (path, isoCode, industryCode = '') => {
    industryCode = industryCode.replace('/','-')
    industryCode = industryCode.replace('&','and')

    const url = `/en-${isoCode.toLowerCase()}/${path.toLowerCase()}/${industryCode.toLowerCase()}`

    return url;
}

/**
 * Convert the compensation info into one compensation string
 * @param {Object}      data    Compensation data from job information
 * @returns {String}            Compensation amount
 */
export const renderCompensation = (data) => {
    let content     = "";
    let currency    = "";

    if (isEmpty(data)) {
        return content;
    }

    if (data.comp_min) {
        if (data.comp_currency) {
            currency = `${data.comp_currency}`;
        }

        content += `${currency}${data.comp_min}`;

        if (data.comp_max) {
            content += ` - ${currency}${data.comp_max}`;
        }

        if (data.comp_period) {
            const period = PERIODS.filter(item => item.id == data.comp_period);

            if (period.length > 0) {
                content += ` ${period[0].name}`;
            }
        }

        return content;
    }

    if (data.comp_other) {
        content = data.comp_other;
        return content;
    }

    return content;
}

/**
 * Generate entity code from entity name
 * @param {String} entityName
 */
export const generateEntityCode = (entityName = "") => {
    if (entityName == "") {
        return;
    }

    return String(entityName)
        .replace(/[^a-zA-Z0-9\u0600-\u06FF]/g, "-") // Allow alphanumeric and arabic only
        .replace(/\.{2,}|-{2,}/g, "-") // Replace any 2 repeated occurrences of replacement
        .replace(/^\.|\.$|^-|-$/g, '') // Eliminate dot or hyphen in beginning and ending
        .toLowerCase();
}


/**
 * Generate amount formatted with comma separated (ex: 1,000,000) , returns formatted amount in string and amount in string
 * @param {String || Number} amount
 */

export const formatAmountWithComma = (amount) => {
    let amountInString = '0';
    let formattedAmount = '0';
    if (amount) {
        amountInString = String(amount).replace(/\D/g, '')
        formattedAmount = (Number(amountInString)).toFixed(1).replace(/\d(?=(\d{3})+\.)/g, '$&,').replace('.0', '')
    }
    return { formattedAmount, amountInString }
}

/**
 * Get date filter values from saved search in directory
 * @param {Object} dateFilter
 */
export const getDateFilterData = (dateFilter = "") => {
    if (dateFilter.min && dateFilter.min.date_raised != undefined) {
        dateFilter.min = dateFilter.min.date_raised;
    } else if (dateFilter.min == undefined) {
        dateFilter.min = '';
    }

    if (dateFilter.max && dateFilter.max.date_raised != undefined) {
        dateFilter.max = dateFilter.max.date_raised;
    } else if (dateFilter.max == undefined) {
        dateFilter.max = '';
    }

    dateFilter.from = dateFilter.min ? new Date(dateFilter.min) : '';
    dateFilter.to = dateFilter.max ? new Date(dateFilter.max) : '';

    return dateFilter;
}

/**
 * remove duplicate objects from an array
 * @param {Array} data
 */
export const removeDuplicateData = (data) => {
    let seen = new Set();
    let uniqueData = data.filter((item) => {
        let key = JSON.stringify(item);
        let duplicate = seen.has(key);
        seen.add(key);
        return !duplicate;
    });

    return uniqueData
}

/**
 * Method used to get the page path from the entity type
 */
export const getPathFromEntityType = (strEntityType = "") => {
    let strPath = "";

    switch (strEntityType) {
    case "STARTUP":
        strPath = "startups";
        break;

    case "INVESTOR":
        strPath = "investors";
        break;

    case "ENABLER":
        strPath = "enablers";
        break;

    case "CORPORATE":
        strPath = "corporates";
        break;

    case "USER":
        strPath = "people";
        break;

    default:
        break;
    }

    return strPath;
};

/* format a count value with comma i.e. 36,000 startups */
export const formatCount = (value) => {
    let count = value + ''; // convert to string
    count = count.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1,');
    return count;
}