import React from 'react';

const ClearFilters = ({clearFilter}) => {
    return (
        <div className="clear-filters-box">
            <h6 className="text-center">No results were found, please change your filters and try again</h6>
            <a className='seeall mb-0' onClick={event => clearFilter(event)}>
                Reset Filters
            </a>
        </div>
    );
}

export default ClearFilters;
