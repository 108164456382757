import React, { Component, useEffect } from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import isEmpty from 'lodash/isEmpty';

// api
import taxonomyApi from '/src/apis/taxonomyApi';

// constants
import { TAXONOMIES } from '/src/lib/constants';
import { fundraisingOptions, numOfEmployees, additionalQuestions } from '../constants/formOptions';

// helpers
import { formatCount } from '/src/lib/helpers';

// react select styles
import { sharedConstants } from 'shared-helpers';
const reactSelectLarge = sharedConstants.reactSelectLarge;
const reactSelectTheme = sharedConstants.reactSelectTheme;



// reused components

class OptionRows extends Component {
    render() {
        return (
            <option value={this.props.data.id}>{this.props.data.name}</option>
        );
    }
}

class MultRows extends Component {
    render() {
        if (this.props.data.isDeleted) return null;

        let name = "";
        if (this.props.data.name) {
            name = this.props.data.name;
        }

        return (
            <div>
                {name}
                <i className='fa fa-times-circle'
                    onClick={() => this.props.onClickHandler(this.props.className, this.props.data)}>
                </i>
            </div>
        );
    }
}

// helper functions

/* 
 * handle adding an item in a multiselect field - returns an object with the item to be added 
 */
export const addMultiselectItem = (listOfOptions, selectedOp) => {
    let tempArr = [];
    if (listOfOptions) {
        tempArr = [...listOfOptions];
    }
    let isDeletedOption = false;

    // check index so we ignore already selected options
    // handle case of deleted list item being selected again
    const index = tempArr.findIndex(item => {
        isDeletedOption = item.isDeleted;
        return (item.itemId === parseInt(selectedOp.value) && item.name === selectedOp.label)
    })

    if (index === -1) {
        const tempObj = { itemId: selectedOp.value, name: selectedOp.label };
        tempArr.push(tempObj);
    } else if (isDeletedOption) {
        tempArr[index].isDeleted = false;
    }
    return tempArr;
}

/* 
 * handle change event for multiselect input
 */
export const handleMultiselectChange = (option, fieldCode, props, callback) => {

    const { state, setState } = props;
    setState({ 
        // updates array of user's selected options
        [fieldCode]: addMultiselectItem(state[fieldCode], option),
        // for forms where we alert users of unsaved changes
        hasUnsavedChanges: true
    });

    // execute callback function
    if (callback) {
        callback(option);
    }

}

// show which tags were selected in the multi dropdown
export const renderMultiSelected = (optionsList, stringName, removeEditMult) => {
    let selectedItems = [];
    if (Array.isArray(optionsList)) {
        let keyCheck = [];
        for (let i = 0; i < optionsList.length; i++) {
            let item = optionsList[i];

            if (!item) {
                return;
            }

            item.itemId = item.itemId ? item.itemId : item.id;
            if (!keyCheck.includes(item.itemId) && item.name) {
                keyCheck.push(item.itemId);
                selectedItems.push(
                    <MultRows key={"mult-" + stringName + item.itemId} onClickHandler={() => removeEditMult(stringName, item)} className={stringName} data={item} />
                );
            }
        }
    }
    if (selectedItems.length > 0) {
        return (<div className="view-selected-ops">{selectedItems}</div>)
    } else {
        return null;
    }
}

// prep uploaded file filename for display purp
const prepFileName = (filePath) => {
    let fileName = "No file chosen";
    if (filePath) {
        let maxChars = 20;
        fileName = filePath.split("/");
        fileName = fileName[fileName.length - 1];
        fileName = (fileName.length > maxChars) ? fileName.substring(0, maxChars) + "..." : fileName;
    }
    return fileName;
}

// exported functions

export const CompanyName = (props) => {
    const { state, isAddPage, handleNameChange, entityType } = props;

    // convert 'startup' to 'Startup'
    const entityTitle = entityType.charAt(0).toUpperCase() + entityType.slice(1);

    if (isAddPage) {
        return (
            <form className="col-md-6" autoComplete="off">
                <label>
                    {entityTitle} Name
                    <span className="req"> *</span>
                </label>
                <input name="name" className={state.invalid_name_err ? `${entityType}Check error` : `${entityType}Check`} type="text" value={state.name} onChange={(event) => handleNameChange(event, 'name', 'company name')} />
                {state.invalid_name_msg &&
                    <div className="errorsContainer">{state.invalid_name_msg}</div>
                }
            </form>
        )
    } else {
        return (
            <div className="col-md-6">
                <label>
                    {entityTitle} Name
                    <span className="req"> *</span>
                </label>
                <input name="name" className={state.invalid_name_err ? 'error' : ''} type="text" value={state.name} disabled />
            </div>
        )
    }
}

export const UploadLogo = (props) => {
    const { state, setState, onDropEdit, entityType } = props;
    return (
        <div className="col-md-6">
            <label>
                {entityType === "user" ? "Profile Picture" : "Logo"}
                {" "}(370 x 370 pixels)
                <span className="req"> *</span>
            </label>
            <div className="fileupload">
                <input
                    className={`image ${state.logo_error ? 'error' : ''}`}
                    name="logo"
                    type="file"
                    onChange={(event) => onDropEdit(event, 'logo', 'logo')}
                    data-filename={prepFileName(state.logoFile)}
                />
            </div>
            {state.logoError &&
                <div className="errorsContainer">{state.logoError}</div>
            }
        </div>
    )
}

// get remaining chars count for description fields
const getCount = (string) => {
    let count = 3000 - (string ? string.length : 0) + '';
    count = formatCount(count);
    return count;
}

export const ShortDesc = (props) => {

    const { state, setState, handleEditChange } = props;
    const count = getCount(state.desInput);

    return (
        <div className="col-md-12">
            <label>
                Short Description
                <span className="req"> *</span>
            </label>
            <textarea
                name="desInput"
                className={`form-control ${state.desInput_error ? 'error' : ''}`}
                value={state.desInput}
                onChange={(event) => handleEditChange(event, 'desInput', 'short description')}
                maxLength="3000">
            </textarea>
            {(count < 50) &&
                <div className="errorsContainer">{count} characters left</div>
            }
        </div>
    )

}

export const InvestmentOffering = (props) => {

    const { state, setState, handleEditChange } = props;
    const count = getCount(state.overview);

    return (
        <div className="col-md-12">
            <label>
                Your Investment Offering and Startup Focus
            </label>
            <textarea
                name="overview"
                className={`form-control ${state.overview_error ? 'error' : ''}`}
                value={state.overview}
                onChange={(event) => {
                    setState({ overview_error: false });
                    handleEditChange(event)
                }}
                maxLength="3000">
            </textarea>
            {(count < 50) &&
                <div className="errorsContainer">{count} characters left</div>
            }
        </div>
    )

}

export const CompanyVid = (props) => {

    const { state, setState, handleEditChange } = props;

    // validate video link
    let videolink = state.videolink && state.videolink.trim();
    if ((videolink) && (videolink.indexOf("http://") == -1 && videolink.indexOf("https://") == -1)) {
        videolink = `https://${videolink}`
    }

    return (
        <div className="col-md-6">
            <label>
                Company Video (URL)
            </label>
            <input type="text" placeholder="Video URL" name="videolink" value={videolink} onChange={(event) => { setState({ isVideoUrlValid: true }); handleEditChange(event) }} />
            {(!state.isVideoUrlValid) &&
                <div className="errorsContainer">Please enter a valid url</div>
            }
        </div>
    )

}

export const ProductImg = (props) => {
    const { state, onDropEdit } = props;
    return (
        <div className="col-md-6">
            <label>
                Product Picture (png, jpeg, gif)
            </label>
            <div className="fileupload">
                <input className="image" name="picture" type="file" onChange={event => onDropEdit(event)} data-filename={prepFileName(state.pictureFile)} />
            </div>
            {state.pictureError &&
                <div className="errorsContainer">{state.pictureError}</div>
            }
        </div>
    )
}

export const DateFounded = (props) => {

    const { state, setState, handleEditChange } = props;

    let now = new Date();
    let allYears = [];
    for (let i = 2000; i <= (now.getFullYear()); i++) {
        allYears.push(<option value={i} key={i}>{i}</option>)
    }

    return (
        <div className="col-md-6">
            <label>
                Date Founded
                <span className="req"> *</span>
            </label>
            <div className="input-group">
                <select name="date" className={`form-control ${state.date_error ? 'error' : ''}`} value={state.date} onChange={(event) => { setState({ date_error: false }); handleEditChange(event, 'date', 'month founded') }}>
                    <option value="">Month</option>
                    <option value="01">Jan</option><option value="02">Feb</option>
                    <option value="03">Mar</option><option value="04">Apr</option>
                    <option value="05">May</option><option value="06">Jun</option>
                    <option value="07">Jul</option><option value="08">Aug</option>
                    <option value="09">Sep</option><option value="10">Oct</option>
                    <option value="11">Nov</option><option value="12">Dec</option>
                </select>
                <select name="year" className={`form-control ${state.year_error ? 'error' : ''}`} value={state.year} onChange={(event) => { setState({ year_error: false }); handleEditChange(event, 'year', 'year founded') }}>
                    <option value="">Year</option>
                    {allYears}
                </select>
            </div>
        </div>
    )

}

async function loadSubIndustries(primaryIndustry, setState) {
    if (primaryIndustry) {
        const subIndustriesArr = [];
        const industries = await taxonomyApi.getSubIndustries(primaryIndustry);
        industries.map(item => subIndustriesArr.push({ label: item.name, value: item.id }));
        setState({ subIndustriesArr });
    }
}

export const PrimaryIndustry = (props) => {

    const { state, setState, handleEditChange } = props;

    // industryFocus called here is reused for secondary indus
    useEffect(() => {
        if (!state.industryFocus) {
            const maxLimit = 300;
            loadSubIndustries(state.primaryFocus, setState);
            taxonomyApi.getIndustries(maxLimit).then(
                response => {
                    setState({ industryFocus: response });
                    let industryFocusOptions;
                    if (response.length > 0) {
                        industryFocusOptions = response.map((industry, i) => <OptionRows key={i} data={industry} />);
                    }
                    setState({ industryFocusOptions })
                }
            );
        }
    }, []); // empty array ensures useEffect is only called once

    if (!state.industryFocus) { return null }

    const handlePrimaryFocusChange = (event, code, msg) => {
        const parentId = event.target.value;
        handleEditChange(event, code, msg);
        loadSubIndustries(parentId, setState);
        setState({ subFocusArr: [], subIndustry: '' });
    }

    return (
        <div className="col-md-6">
            <label>Primary Industry<span className="req"> *</span></label>
            <select name="primaryFocus" className={state.primaryFocus_error ? 'error' : ''} value={state.primaryFocus} onChange={(event) => { setState({ primaryFocus_error: false }); handlePrimaryFocusChange(event, 'primaryFocus', 'primary industry') }}>
                <option value="">Select Industry</option>
                {state.industryFocusOptions}
            </select>
        </div>
    )

}

export const SubIndustries = (props) => {

    const { state, setState } = props;

    const handleSubIndustryChange = (option) => {
        const tempEditParams = { ...state.editParams };

        tempEditParams.subFocus = option.value;

        setState({
            subIndustry: option,
            editParams: tempEditParams,
        });
    }

    let isDisabled = (!state.primaryFocus ? true : false);
    isDisabled = (isDisabled || (!state.subIndustriesArr || state.subIndustriesArr.length == 0))

    return (
        <div className="col-md-6">
            <label>Sub-Industries</label>
            <AsyncSelect
                // config
                cacheOptions
                defaultOptions={state.subIndustriesArr}
                isSearchable={false}
                // functions
                loadOptions={() => loadSubIndustries(state.primaryFocus, setState)}
                onChange={handleSubIndustryChange}
                // input
                isDisabled={isDisabled}
                placeholder="Select Sub-Industry"
                value={state.subIndustry}
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
        </div>
    )

}

export const SecondaryIndustry = (props) => {

    const { state, removeEditMult, setState } = props;

    const { focusArr } = state;
    let count = 0;
    if (focusArr) {
        focusArr.map(item => {
            if (!item.isDeleted) {
                count++;
            }
        });
    }
    const isLimitReached = (count >= 4) ? true : false;

    const handleSecIndusChange = (option) => {
        // don't allow to select same industry as primary
        if (option.value !== state.primaryFocus) {
            let focusArr = addMultiselectItem(state.focusArr, option)
            setState({ focus: option, focusArr });
        }
    }

    let options = [];
    if (state.industryFocus) {
        state.industryFocus.map(item => options.push({ label: item.name, value: item.id }));
    }

    return (
        <div className="col-md-6">
            <label>Secondary Industry (max 4)</label>
            <Select
                // config
                isDisabled={isLimitReached}
                options={options}
                // functions
                onChange={handleSecIndusChange}
                // input
                placeholder="Select Secondary Industry"
                value="Select Secondary Industry"
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
            {renderMultiSelected(focusArr, 'focusArr', removeEditMult)}
        </div>
    )

}

export const IndustryFocusMult = (props) => {

    const { state, setState, removeEditMult } = props;
    const { industryFocus, focusArr } = state;
    const code = 'focusArr'; // state variable where the selected value is saved

    useEffect(() => {
        if (!industryFocus) {
            const maxLimit = 300;
            taxonomyApi.getIndustries(maxLimit).then(
                response => {
                    let options = [];
                    if (response.length > 0) {
                        response.map(item => options.push({ label: item.name, value: item.id }));
                    }
                    setState({
                        industryFocus: response,
                        industryFocOps: options
                    });
                }
            );
        }
    }, []); // empty array ensures useEffect is only called once

    return (
        <div className="col-md-6">
            <label>Industry Focus</label>
            <Select
                // config
                cacheOptions
                options={state.industryFocOps}
                // functions
                onChange={(e) => handleMultiselectChange(e, code, props)}
                // input
                placeholder="Select Industry Focus"
                value="Select Industry Focus"
                // value={this.state.industry}
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
            {renderMultiSelected(focusArr, 'focusArr', removeEditMult)}
        </div>
    )

}

export const FoundersCount = (props) => {
    const { state, setState, handleEditChange } = props;
    return (
        <div className="col-md-6">
            <label>
                Number of Founders
                <span className="req"> *</span>
            </label>
            <input name="number" className={state.number_error ? 'error' : ''} value={state.number} onChange={(event) => { setState({ number_error: false }); handleEditChange(event, 'number', 'number of founders') }} />
            {(state.number !== "" && !(state.number >= 1 && (state.number <= 10))) &&
                <div className="errorsContainer">Number of founders should be between 1 and 10</div>
            }
        </div>
    )
}

export const BusinessType = (props) => {
    const { state, setState, removeEditMult } = props;
    const { typeArr, businessType } = state;

    useEffect(() => {
        if (!businessType || !businessType.length > 0) {
            taxonomyApi.getTaxonomyType(TAXONOMIES.businessType).then(
                response => {
                    let temp = [];
                    response.map(item =>
                        temp.push({ label: item.name, value: item.id })
                    );
                    setState({ businessType: temp })
                }
            )
        }
    }, []); // empty array ensures useEffect is only called once

    const handleBTypeChange = (option) => {
        if (option) {
            let ind = state.errorMessages.indexOf('business type');
            if (ind > -1) {
                state.errorMessages.splice(ind, 1);
            }
        }

        setState({
            type: option,
            typeArr: addMultiselectItem(typeArr, option),
            errorMessages: state.errorMessages,
            type_error: false,
        });
    }

    return (
        <div className="col-md-6">
            <label>
                Business Type
                <span className="req"> *</span>
            </label>
            <AsyncSelect
                // config
                cacheOptions
                defaultOptions={businessType}
                isSearchable={false}
                // functions
                onChange={handleBTypeChange}
                // input
                placeholder="Select Business Type"
                value="Select Business Type"
                // style
                classNamePrefix={`margin-reset ${state.type_error ? 'react-select-error ' : ''}`}
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
            {renderMultiSelected(state.typeArr, 'typeArr', removeEditMult)}
        </div>
    )
}

export const LinkedInstitutions = (props) => {
    const { state, handleChange, chooseInst, removeInstMult, setState } = props;

    let instPreload = '';
    if (state.institutions && state.institutions.length > 0) {
        let keyCheck = [];
        instPreload = state.institutions.map((preload, i) => {
            if (!keyCheck.includes(preload.id)) {
                keyCheck.push(preload.id);
                return (<li key={preload.id} onClick={() => chooseInstitution(preload)}>{preload.title}</li>)
            }
        })
    }

    let institMult = [];
    if (!isEmpty(state.institutionArr)) {
        state.institutionArr.map((v, i) => {
            if (!v.isDeleted) {
                institMult.push(
                    <MultRows key={v.linked_id} onClickHandler={() => removeInstMult(i, true)} className='typeArr' data={v} />
                )
            }
        });
    }

    const chooseInstitution = (preload) => {
        chooseInst(preload);
        setState({ institution: '' });
    }

    return (
        <div className="col-md-6">
            <label>
                Institutions Associated with your Startup (e.g. Accelerator Programs, Investors etc.)
            </label>
            {/* value={state.institution} is required to show preloaded list */}
            <input name="institution" value={state.institution} className="pre" type="text" onChange={(event) => handleChange(event)} placeholder="Select Institution" />
            {instPreload &&
                <div className="dropdown-form-list">{instPreload}</div>
            }
            {institMult.length > 0 &&
                <div className="view-selected-ops">{institMult}</div>
            }
        </div>
    )
}

export const HeadquarterCountry = (props) => {
    const { state, setState, handleHQChange } = props;
    return (
        <div className="col-md-6">
            <label>
                Headquarters (Country)
                <span className="req"> *</span>
            </label>
            <select name="incorporated"
                className={`form-control ${state.incorporated_error ? 'error' : ''}`}
                value={state.incorporated}
                onChange={(event) => {
                    setState({ incorporated_error: false });
                    handleHQChange(event, 'edit', 'incorporated', 'headquarters')
                }}>
                <option value="">Select Country</option>
                {state.locations.length !== 0 &&
                    state.locations.map((loc, i) => <OptionRows key={i} data={loc} />)
                }
            </select>
        </div>
    )
}

export const HeadquarterCity = (props) => {
    const { state, setState, loadCities } = props;
    return (
        <div className="col-md-6">
            <label>
                Headquarters (City)
            </label>
            <AsyncSelect
                // config
                cacheOptions
                loadOptions={(input, callback) => loadCities(input, callback, state.incorporated)}
                defaultOptions={state.citiesArr}
                isSearchable={false}
                //
                value={state.city}
                onChange={(option) => {
                    const editParams = { ...state.editParams };
                    editParams['city'] = option.value;
                    setState({ city: option, editParams });
                }}
                placeholder="Select City"
                isDisabled={!state.incorporated}
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
        </div>
    )
}

export const GeoFocus = (props) => {
    const { state, setState, removeEditMult } = props;

    const handleGeoFocusChange = (option) => {
        setState({
            geofocus: option,
            geofocusArr: addMultiselectItem(state.geofocusArr, option),
        });
    }

    let geoFocusOps = [];
    if (state.locations) {
        state.locations.map(item => geoFocusOps.push({ label: item.name, value: item.id }));
    }

    if (state.geofocusArr) {
        geoFocusOps.forEach((location) => {
            state.geofocusArr.forEach((item) => {
                if (location.label == item.name) {
                    location.isDisabled = item.isDeleted ? false : true;
                }
            })
        });

        state.geofocusArr.sort((a, b) => {
            let loca = a.name.toLowerCase(), locb = b.name.toLowerCase();

            if (loca < locb) { return -1 }
            if (loca > locb) { return 1 }
            return 0;
        });
    }

    return (
        <div className="col-md-6">
            <label>Geographic Focus</label>
            <Select
                // config
                options={geoFocusOps}
                isOptionDisabled={(option) => option.isDisabled}
                // functions
                onChange={handleGeoFocusChange}
                // input
                placeholder="Select Geographic Focus"
                value="Select Geographic Focus"
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
            {renderMultiSelected(state.geofocusArr, 'geofocusArr', removeEditMult)}
        </div>
    )
}

export const ListOfBranches = (props) => {
    const { state, setState, removeEditMult } = props;

    const handleFieldChange = (option) => {
        setState({
            branches: option,
            branchesArr: addMultiselectItem(state.branchesArr, option),
        });
    }

    let fieldOps = [];
    state.locations.map(item => fieldOps.push({ label: item.name, value: item.id }));

    return (
        <div className="col-md-6">
            <label>Branches</label>
            <Select
                // config
                options={fieldOps}
                // functions
                onChange={handleFieldChange}
                // input
                placeholder="Select Branches"
                value="Select Branches"
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
            {renderMultiSelected(state.branchesArr, 'branchesArr', removeEditMult)}
        </div>
    )
}

export const DevStage = (props) => {
    const { state, setState, handleEditChange } = props;
    let { stageDevelopment, stageOptions } = state;

    useEffect(() => {
        if (!stageDevelopment || !stageDevelopment.length > 0) {
            taxonomyApi.getTaxonomyType(TAXONOMIES.devStage).then(
                response => {
                    stageDevelopment = response;
                    if (stageDevelopment.length > 0) {
                        let stageOptions = stageDevelopment.map((type, i) => <OptionRows key={i} data={type} />);
                        setState({ stageDevelopment, stageOptions })
                    }
                }
            )
        }
    }, []); // empty array ensures useEffect is only called once

    return (
        <div className="col-md-4">
            <label>
                Development Stage
                <span className="req"> *</span>
            </label>
            <select
                name="stage"
                className={state.stage_error ? 'error' : ''}
                value={state.stage}
                onChange={(event) => { setState({ stage_error: false }); handleEditChange(event, 'stage', 'development stage') }}
            >
                <option value=''>Select Development Stage</option>
                {stageOptions}
            </select>
        </div>
    )
}

export const RevenueGen = (props) => {
    const { state, setState, handleEditChange } = props;
    return (
        <div className="col-md-4">
            <label>
                Revenue Generating
                <span className="req"> *</span>
            </label>
            <select
                name="revenue"
                className={state.revenue_error ? 'error' : ''}
                value={state.revenue}
                onChange={(event) => { setState({ revenue_error: false }); handleEditChange(event, 'revenue', 'revenue generation status') }}
            >
                <option value="">Revenue Generation Status</option>
                <option value="0">No</option>
                <option value="1">Yes</option>
            </select>
        </div>
    )
}

export const CurrentlyFundraising = (props) => {
    const { state, setState, handleEditChange } = props;
    return (
        <div className="col-md-4">
            <label>
                Currently Fundraising
                <span className="req"> *</span>
            </label>
            <select
                name="funding"
                className={state.funding_error ? 'error' : ''}
                value={state.funding}
                onChange={(event) => { setState({ funding_error: false }); handleEditChange(event, 'funding', 'currently fundraising') }}
            >
                <option value="">Select Fundraising Status</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
            </select>
        </div>
    )
}

export const DesiredFundingAmt = (props) => {
    const { state, setState, handleEditChange } = props;
    return (
        <div className="col-md-4">
            <label>
                Desired Funding Amount
                <span className="req"> *</span>
            </label>
            <select
                name="fundraising"
                className={state.fundraising_error ? 'error' : ''}
                value={state.fundraising}
                onChange={(event) => { setState({ fundraising_error: false }); handleEditChange(event, 'fundraising', 'desired fundraising amount') }}
            >
                <option value="">Select Fundraising Amount</option>
                {fundraisingOptions.map((fund, i) => <OptionRows key={i} data={fund} />)}
            </select>
        </div>
    )
}

export const EmployeeCount = (props) => {
    const { state, setState, handleEditChange } = props;
    return (
        <div className="col-md-4">
            <label>
                Number of Employees
                <span className="req"> *</span>
            </label>
            <select
                name="howamny"
                className={state.howamny_error ? 'error' : ''}
                value={state.howamny}
                onChange={(event) => { setState({ howamny_error: false }); handleEditChange(event, 'howamny', 'number of employees') }}
            >
                <option value="">Select Number of Employees</option>
                {numOfEmployees.map((emp, i) => <OptionRows key={i} data={emp} />)}
            </select>
        </div>
    )
}

export const Support = (props) => {
    const { state, handleEditSupport } = props;

    const renderSupport = () => {
        let { supportData, supportArr } = state;
        let content = [];
        if (supportData && supportData.length > 0) {
            supportData.map((v, i) => {
                let checked = false;
                if (supportArr.find(x => (x.itemId === v.id && !x.isDeleted))) {
                    checked = true;
                }

                content.push(
                    <div className="support-checkbox" key={"support-" + i}>
                        <input type="checkbox" value='1' name="mentorship" checked={checked} onChange={() => handleEditSupport(i)} style={{ width: 'auto', marginRight: 7, marginBottom: 9 }} />
                        <label>{v.name}</label>
                    </div>
                );
            });
        }
        return content;
    }

    return (
        <div className="col-md-12 margin-reset" style={{ marginBottom: 15 }}>
            <label>
                Which types of support are you looking for?
            </label>
            <div className="row m-0">
                {renderSupport()}
            </div>
        </div>
    )
}

export const AboutQuestions = (props) => {
    const { state, handleEditChange } = props;
    let questions = additionalQuestions;
    let renderQ = [];
    questions.map((item, i) => {
        renderQ.push(
            <div className="col-md-12" key={"render-q-" + i}>
                <label>{item.question}</label>
                <textarea name={item.editKey} value={state[item.editKey]} onChange={handleEditChange} maxLength="3000"></textarea>
            </div>
        )
    })
    return (<>{renderQ}</>)
}

export const SocialForm = (props) => {

    const { state, setState, handleEditChange, entityType } = props;

    // validate
    let website = state.website && state.website.trim();
    if ((website) && (website.indexOf("http://") == -1 && website.indexOf("https://") == -1)) {
        website = `https://${website}`
        setState({ website });
    }

    let errorMsg = "";
    if (entityType !== "user" && state.urlError) {
        errorMsg += "Please enter a valid URL. ";
    }

    if (entityType === "startup") {
        if (!state.isIosUrlValid) {
            errorMsg += "Please enter a valid Apple App Store URL. ";
        }
        if (!state.isAndroidUrlValid) {
            errorMsg += "Please enter a valid Google Play Store URL. ";
        }
    }

    if (!state.isWebsiteUrlValid) {
        errorMsg += "Please enter a valid website URL. ";
    }

    return (
        <>
            <div className="col-md-4">
                <div className="input-group">
                    <span className="input-group-text">
                        <i className="fab fa-linkedin-in"></i>
                    </span>
                    <input type="text" placeholder="LinkedIn URL" className={`form-control linkedin ${!state.islinkedinUrlValid ? 'error' : ''}`} name="linkedin" value={state.linkedin} onChange={(event) => { setState({ islinkedinUrlValid: true }); handleEditChange(event) }} />
                </div>
            </div>

            <div className="col-md-4">
                <div className="input-group">
                    <span className="input-group-text">
                        <i className="fab fa-facebook-f"></i>
                    </span>
                    <input type="text" placeholder="Facebook URL" className={`form-control facebook ${!state.isfacebookUrlValid ? 'error' : ''}`} name="facebook" value={state.facebook} onChange={(event) => { setState({ isfacebookUrlValid: true }); handleEditChange(event) }} />
                </div>
            </div>

            <div className="col-md-4">
                <div className="input-group">
                    <span className="input-group-text">
                        <i className="fa-twitter fab"></i>
                    </span>
                    <input type="text" placeholder="Twitter URL" className={`form-control twitter ${!state.istwitterUrlValid ? 'error' : ''}`} name="twitter" value={state.twitter} onChange={(event) => { setState({ istwitterUrlValid: true }); handleEditChange(event) }} />
                </div>
            </div>

            {entityType !== "user" &&
                <div className="col-md-4">
                    <div className="input-group">
                        <span className="input-group-text">
                            <i className="fas fa-globe"></i>
                        </span>
                        <input type="text" placeholder="Website URL" className={`form-control website ${!state.isWebsiteUrlValid ? 'error' : ''}`} name="website" value={state.website} onChange={(event) => { setState({ isWebsiteUrlValid: true }); handleEditChange(event) }} />
                    </div>
                </div>
            }

            {entityType === "startup" &&
                <div className="col-md-4">
                    <div className="input-group">
                        <span className="input-group-text">
                            <i className="fa-apple fab" style={{ fontSize: 19 }}></i>
                        </span>
                        <input type="text" placeholder="Apple App Store URL" className={`form-control ios-app-url ${!state.isIosUrlValid ? 'error' : ''}`} name="iosAppUrl" value={state.iosAppUrl} onChange={(event) => { setState({ isIosUrlValid: true }); handleEditChange(event) }} />
                    </div>
                </div>
            }

            {entityType === "startup" &&
                <div className="col-md-4">
                    <div className="input-group">
                        <span className="input-group-text">
                            <i className="fa-google-play fab"></i>
                        </span>
                        <input type="text" placeholder="Google Play Store URL" className={`form-control android-app-url ${!state.isAndroidUrlValid ? 'error' : ''}`} name="androidAppUrl" value={state.androidAppUrl} onChange={(event) => { setState({ isAndroidUrlValid: true }); handleEditChange(event) }} />
                    </div>
                </div>
            }

            {entityType === "user" &&
                <>
                    {/* not sure if this should be visible since users aren't allowed to edit it on this page */}
                    {/* <div className="col-md-4">
                        <div className="input-group">
                            <span className="input-group-text">
                                <i className="fa fa-envelope"></i>
                            </span>
                            <input type="text" placeholder="Email" className="form-control" name="email" value={state.email} onChange={(event) => handleEditChange(event)} disabled/>
                        </div>
                    </div> */}
                    <div className="col-md-4">
                        <div className="input-group">
                            <span className="input-group-text">
                                <i className="fa fa-phone"></i>
                            </span>
                            <input type="text" placeholder="Phone" className="form-control" name="phone" value={state.phone} onChange={(event) => handleEditChange(event)} />
                        </div>
                    </div>
                </>
            }

            {errorMsg.length > 0 &&
                <div className="col-md-12">
                    <div className="errorsContainer">{errorMsg}</div>
                </div>
            }
        </>
    )
}

export const InvestorType = (props) => {

    const { state, setState, handleEditChange, entityType } = props;
    const entity = entityType.charAt(0).toUpperCase() + entityType.slice(1);

    if (!state.list_investorType || state.list_investorType.length < 1) {
        return (null); // api response not loaded yet
    }

    return (
        <div className="col-md-6">
            <label>
                {entity} Type
                <span className="req"> *</span>
            </label>
            <select
                name="investorT"
                className={state.investorT_error ? 'error' : ''}
                value={state.investorT}
                onChange={(event) => {
                    setState({ investorT_error: false });
                    handleEditChange(event, 'investorT', 'company type')
                }}
            >
                <option value=''>Select {entity} Type</option>
                {state.list_investorType.map((dev, i) => <OptionRows key={i} data={dev} />)}
            </select>
        </div>
    )
}

export const TicketSize = (props) => {
    const { state, handleTicketSizeInput, handleTicketSizeSelect } = props;
    return (
        <>
            <div className="col-md-4">
                <label>Minimum Ticket Size</label>
                <div className="input-group row m-0">
                    <input type='number' min={0} max={999} name='min_ticket_value' value={state.min_ticket_value} className={`form-control ticketsize-input col-7 ${state.date_error ? 'error' : ''}`} onChange={(ev) => handleTicketSizeInput(ev, 'min', 'edit')} />
                    <select className='form-control ticketsize-select col-5' name='min_ticket' value={state.min_ticket} onChange={(ev) => handleTicketSizeSelect(ev, 'min', 'edit')}>
                        <option value="none">none </option>
                        <option value="Thousand">Thousand</option>
                        <option value="Million">Million</option>
                    </select>
                </div>
            </div>
            <div className="col-md-4">
                <label>Maximum Ticket Size</label>
                <div className="input-group row m-0">
                    <input type='number' min={0} max={999} name='max_ticket_value' value={state.max_ticket_value} className='form-control ticketsize-input col-7' onChange={(ev) => handleTicketSizeInput(ev, 'max', 'edit')} />
                    <select className='form-control ticketsize-select col-5' name='max_ticket' value={state.max_ticket} onChange={(ev) => handleTicketSizeSelect(ev, 'max', 'edit')} >
                        <option value="none">none </option>
                        <option value="Thousand">Thousand</option>
                        <option value="Million">Million</option>
                    </select>
                </div>
            </div>
        </>
    )
}

export const LinkedStartups = (props) => {

    const { state, handleChange, choseSingleStartup, setState } = props;

    let startupPreload = '';
    if (state.startupPreload && state.startupPreload.length > 0) {
        let keyCheck = [];
        startupPreload = state.startupPreload.map((preload, i) => {
            if (!keyCheck.includes(preload.id)) {
                keyCheck.push(preload.id);
                return (<li key={preload.id} onClick={() => chooseStartup(preload.id)}>{preload.name}</li>)
            }
        })
    }

    let startupMult = [];
    if (Array.isArray(state.startups) && state.startups.length > 0) {
        state.startups.map((v, i) => {
            if (!v.isDeleted) {
                startupMult.push(
                    <MultRows key={i + "_" + v.linked_id} onClickHandler={() => removeStartup(i, true)} data={v} />
                )
            }
        });
    }

    const chooseStartup = (id) => {
        choseSingleStartup(id)
        setState({ startupTemp: '' })
    }

    const removeStartup = (index, isEdit) => {
        let { startups } = state;
        if (isEdit) {
            startups[index].isDeleted = true;
        } else {
            startups.splice(index, 1);
        }
        setState({ startups });
    }

    return (
        <div className="col-md-6">
            <label>
                Startup Relations (from Accelerator Cohorts, etc.)
            </label>
            <input type="text" name="startupTemp" className="pre" value={state.startupTemp} onChange={handleChange} placeholder="Startup Names" />
            {startupPreload &&
                <div className="dropdown-form-list">{startupPreload}</div>
            }
            {startupMult.length > 0 &&
                <div className="view-selected-ops">{startupMult}</div>
            }
        </div>
    )
}

export const DevStageMult = (props) => {
    const { state, setState, removeEditMult } = props;
    const { list_fundingStage } = state;

    const handleFieldChange = (option) => {
        setState({
            stageArr: addMultiselectItem(state.stageArr, option),
        });
    }

    let fieldOps = [];
    if (list_fundingStage) {
        list_fundingStage.map(item => fieldOps.push({ label: item.name, value: item.id }));
    }

    return (
        <div className="col-md-6">
            <label>
                Development Stages
            </label>
            <Select
                // config
                cacheOptions
                options={fieldOps}
                // functions
                onChange={handleFieldChange}
                // input
                placeholder="Select Development Stages"
                value="Select Development Stages"
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
            {renderMultiSelected(state.stageArr, 'stageArr', removeEditMult)}
        </div>
    )
}

export const FullName = (props) => {
    const { state, handleEditChange } = props;
    return (
        <div className="col-md-6">
            <label>
                Full Name
                <span className="req"> *</span>
            </label>
            <div className="input-group">
                <input
                    name="fname"
                    className={`form-control fname ${state.fname_error ? 'error' : ''}`}
                    type="text"
                    value={state.fname}
                    onChange={handleEditChange}
                    placeholder="First Name" />

                <input
                    name="lname"
                    className={`form-control lname ${state.lname_error ? 'error' : ''}`}
                    type="text"
                    value={state.lname}
                    onChange={handleEditChange}
                    placeholder="Last Name" />
            </div>
        </div>
    )
}

export const Nationality = (props) => {
    const { state, handleEditChange } = props;
    return (
        <div className="col-md-6">
            <label>
                Nationality
            </label>
            <select name="nationality" value={state.nationality} onChange={handleEditChange}>
                <option value="">Select Nationality</option>
                {(state.locations && state.locations.length > 0) &&
                    state.locations.map((loc, i) => <OptionRows key={i} data={loc} />)
                }
            </select>
        </div>
    )
}

export const Location = (props) => {
    const { state, handleEditChange } = props;
    return (
        <div className="col-md-6">
            <label>
                Location
            </label>
            <select name="location" value={state.location} onChange={handleEditChange}>
                <option value="">Select Location</option>
                {(state.locations && state.locations.length > 0) &&
                    state.locations.map((loc, i) => <OptionRows key={i} data={loc} />)
                }
            </select>
        </div>
    )
}

export const Gender = (props) => {
    const { state, handleEditChange } = props;
    return (
        <div className="col-md-6">
            <label>
                Gender
            </label>
            <select name="gender" value={state.gender} onChange={handleEditChange}>
                <option value="">Select Gender</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
            </select>
        </div>
    )
}

export const UserAboutSection = (props) => {

    const { state, handleEditChange } = props;

    return (
        <>
            <div className="col-md-12">
                <label>About Me</label>
                <textarea
                    type="text"
                    placeholder="About Me"
                    name="whatido"
                    value={state.whatido}
                    onChange={handleEditChange}
                    maxLength="3000">
                </textarea>
            </div>
            <div className="col-md-12">
                <label>Startup Experience</label>
                <textarea
                    type="text"
                    placeholder="Startup Experience"
                    name="startup"
                    value={state.startup}
                    onChange={handleEditChange}
                    maxLength="3000">
                </textarea>
            </div>
        </>
    )

}

export const SelectStartup = (props) => {

    const { state, setState, handleChange, segTrack, id, chooseStartup } = props;

    let startupOps = [];
    if (state.mystartups && state.mystartups.length > 0) {
        state.mystartups.map((data, i) => {
            if (isEmpty(data) || isEmpty(data.entity_data)) {
                return;
            }
            startupOps.push(<option value={data.entity_data.id} key={"startup_" + i}>{data.entity_data.name}</option>)
        })
    }

    const handleStartupChange = (event) => {
        handleChange(event);
        chooseStartup(event);
    }

    return (
        <>
            <div className="col-md-4">
                <select
                    name="startup"
                    className={`applicationStartup ${state.startup_error ? 'error' : ''}`}
                    value={state.nid}
                    onChange={(event) => { setState({ startup_error: false }); handleStartupChange(event) }}
                >
                    <option value="">Select Startup</option>
                    {startupOps}
                </select>
            </div>
            <p className="ShowOnMobile or mt-2">OR</p>
            <div className="col-md-4">
                <a className="btn btn-main-outline btn-block" onClick={(event) => segTrack('Clicked Create Startup from Application')} href={`/startups/add?destination=${id}`}>
                    Create New Startup Profile
                </a>
            </div>
        </>
    )

}

export const RegionMultiSelect = (props) => {
    const { state, setState, removeEditMult, getRegionLocations } = props;
    const { regionsList, regionsOptions } = state;
    const code = 'regionArr';

    useEffect(() => {
        if (!regionsList) {
            taxonomyApi.getRegions().then(
                response => {
                    let options = [];
                    if (response.length > 0) {
                        response.map(item => options.push({ label: item.name, value: item.id }));
                    }
                    setState({
                        regionsList: response,
                        regionsOptions: options
                    });
                }
            );
        }
    }, []); // empty array ensures useEffect is only called once

    const multiselectCallback = (region) => {
        getRegionLocations('add', region.value);
    } 

    const handleRemove = (code, data) => {
        removeEditMult(code, data);
        getRegionLocations('remove', data);
    }

    return (
        <div className="col-md-6">
            <label>Region</label>
            <Select
                // config
                options={regionsOptions}
                // functions
                onChange={(e) => handleMultiselectChange(e, code, props, multiselectCallback)}
                // input
                placeholder="Select Region"
                value="Select Region"
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
            {renderMultiSelected(state[code], code, handleRemove)}
        </div>
    )
}

export const CountryMultiSelect = (props) => {
    const { state, setState, removeEditMult } = props;
    const { countryList, countryOptions } = state;

    const code = 'countryArr';

    useEffect(() => {
        if (!countryList) {
            taxonomyApi.getLocations().then(
                response => {
                    let options = [];
                    if (response.length > 0) {
                        response.map(item => options.push({ label: item.name, value: item.id }));
                    }
                    setState({
                        countryList: response,
                        countryOptions: options
                    });
                }
            );
        }
    }, []); // empty array ensures useEffect is only called once

    return (
        <div className="col-md-6">
            <label>Country</label>
            <Select
                // config
                options={countryOptions}
                // functions
                onChange={(e) => handleMultiselectChange(e, code, props)}
                // input
                placeholder="Select Country"
                value="Select Country"
                // style
                classNamePrefix="margin-reset "
                components={{ IndicatorSeparator: () => null }}
                theme={reactSelectTheme}
                styles={reactSelectLarge}
            />
            {renderMultiSelected(state[code], code, removeEditMult)}
        </div>
    )
}
