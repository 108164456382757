import React, { useState } from 'react';
import { PUBLIC_IMG_URL } from '/src/lib/endpoints';
import { customMarketMapApi } from '/src/apis/customMarketMapApi';



export const DeleteMapModal = (props) => {

    const { showDeleteModal, mapToDelete, closeModal, setDeletedMapIds, userId, updateMapView } = props;
    const [isDeleting, setIsDeleting] = useState(false);

    if (!showDeleteModal || !mapToDelete) {
        return null;
    }

    const handleDelete = () => {
        setIsDeleting(true);
        const map_id = mapToDelete.id;
        customMarketMapApi.handleDeleteMap(map_id, userId).then(
            response => {
                setIsDeleting(false);
                setDeletedMapIds(prevState => [...prevState, map_id]);
                updateMapView();
            }
        )
    }

    return (
        <div>
            <div className="filterBackground" onClick={closeModal}></div>
            <div className="update popup" style={{ minHeight: "auto", top: "25px !important", marginTop: 0 }}>
                <a className="close" onClick={closeModal}>
                    <img src={`${PUBLIC_IMG_URL}/icons/close.png`} alt="" />
                </a>

                <div className="content text-center" style={{ backgroundColor: '#fff' }}>

                    <h2 className="important mb-2"></h2>
                    <div className="text-center">
                        Are you sure you want to delete the market map <strong>'{mapToDelete.name}'</strong>?
                    </div>

                    <a className={isDeleting ? "btn btn-main mt-3 disabled" : "btn btn-main mt-3"} onClick={handleDelete}>
                        {isDeleting &&
                            <i className="fas fa-circle-notch fa-spin" style={{ marginRight: 8 }}></i>
                        }
                        Delete
                    </a>

                </div>

            </div>
        </div>
    )
}